import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PrimaryTab from "../components/tabs/primary";
import PrimaryTitle from "../components/typhography/title-primary";
import Summary from "../components/home/Summary";
import ProjectInbox from "../components/home/project-inbox/index";
import { useStore } from "../store";
import { get } from "../services";
import Inbox from "../components/home/inbox";


const Home = () => {
  const location = useLocation()
  const store = useStore();
  console.log(store, 'store')
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get('tab');
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({})


  useEffect(() => {
    get(`user/activity_summary`)
    .then((res) => {
      if (res && res.length > 0) {
        setSummary(res[0]);
      }
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [])
  

  const tabs=[
    {
      label:"Summary",
      tab:<Summary summary={summary}/>
    },
    {
      label:"Project Inbox",
      tab:<ProjectInbox />
    },
    {
      label:"Inbox",
      tab:<Inbox />
    }
  ]

  if(loading){
    return null
  }
  return (
    <div className="flex flex-col text-left">
      <div className="my-5">
      <PrimaryTitle title="Home" />
      </div>
      <PrimaryTab tabs={tabs} selected={defaultTab}/>
    </div>
  );
};

export default Home;
