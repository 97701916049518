import React from 'react'
import Table from "../../table/table";

export default function Completed() {
    const columns = [
        {
            field: "trip_id",
            headerName: "REVIEW ID",
          },
          {
              field: "operation",
              headerName: "Opertaion",
            },
            {
              field: "",
              headerName: "Submitted On",
            },
            {
              field: "",
              headerName: "Accepted On",
            },
            {
              field: "",
              headerName: "Close On",
            },
            {
              field: "",
              headerName: "Forward",
            },
            {
              field: "",
              headerName: "Re-Open",
            }
        
      ]
  return (
    <div className="flex flex-col gap-3">
      <h6 className="font-semibold text-[20px]">Completed Tasks</h6>

      <Table columns={columns} data={[]} />
    </div>
  )
}
