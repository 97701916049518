import React, { useState } from "react";
import LayoutSecondary from "../../components/layouts/secondary";
import { useNavigate } from "react-router-dom";
import PrimaryBox from "../../components/box/box-primary";
import Input from "../../components/inputs/input";
import TelePhone from "../../components/inputs/telephone";
import Select from "../../components/inputs/select";
import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import { post } from "../../services";
import { toast, Bounce } from "react-toastify";
import { useStore } from "../../store";

export default function AddRTL() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const { ssoUserID } = useStore();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);


    post("ftls/add", {...formData, "userId": ssoUserID})
      .then((res) => {
        console.log(res);
        if (res) {
          //setFormData({})
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          navigate('/rtl')
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  return (
    <LayoutSecondary
      bCrumpLabel={"RTL Search"}
      bCrumpPath={"/rtl"}
      title="Add RTL Info"
    >
      <PrimaryBox>
        <form
          onChange={handleChange}
          onSubmit={handleSubmit}
          className="flex flex-col gap-6"
        >
         
          <div className="flex gap-6">
            <Input
              label="Title"
              id="title"
              name="title"
              value={formData.title}
              size="sm"
            />
            <Input
              label="First Name"
              id="FName"
              name="FName"
              value={formData.FName}
            />
            <Input
              label="Last Name"
              id="LName"
              name="LName"
              value={formData.LName}
            />
          </div>
          <div className="flex gap-6">
            <div className="w-[200px]">
              <TelePhone
                label="Phone"
                id="phone"
                name="phone"
                value={formData.phone}
              />
            </div>
            <div className="w-[200px]">
              <TelePhone
                label="Emergency"
                id="emergencyPhone"
                name="emergencyPhone"
                value={formData.emergencyPhone}
              />
            </div>
            <div className="w-[200px]">
              <TelePhone label="Fax" id="fax" name="fax" value={formData.fax} />
            </div>
          </div>

          <div className="flex gap-6">
            <div className="w-[200px]">
              <Input label="Email" id="email" name="email" value={formData.email} />
            </div>
            <div className="w-[100px]">
              <Select
                label="Region"
                name="region"
                id="region"
                options={[...Array(13)].map((_, i) => {
                  return { label: `${i + 1}`, value: i + 1 };
                })}
                selected={formData.region}
              />
            </div>
            
          </div>
         
          <div className="flex gap-2 mt-5">
            <SecondaryButton
              className={"py-3 px-10"}
              onClick={() => navigate("/rtl")}
              label={"Cancel"}
              type="button"
            />
            <PrimaryButton
              type="submit"
              className={"py-3 px-10"}
              label={"Add RTL Info"}
              //onClick={handleFormSubmit}
            />{" "}
          </div>
        </form>
      </PrimaryBox>
    </LayoutSecondary>
  );
}
