import React from "react";
import Table from "../../table/table";

export default function Changes({ data }) {
  const columns = [

    {
      field: "trip_id",
      headerName: "REVIEW ID",
      url: '/accept-a-case',
      urlParams: ['trip_tracker_uid']
    },
    {
      field: "review_date",
      headerName: "REVIEW DATE"
    },
    {
      field: "rev_name",
      headerName: "REVIEWER"
    },
    {
      field: "qc_status",
      headerName: "QC STATUS",
      cellRenderer: function ({ value }) {
        const type = value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "In Progress" ? 'bg-[#FEF3C7] text-[#92400E]' : type === "Not Started" ? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
          {type}
        </div>
      }
    },
    {
      field: "creation_ts",
      headerName: "RECEIVED ON"
    },
    {
      field: "tran_type",
      headerName: "OPERATION",
      cellRenderer: function ({ value }) {
        const type = value
        return <div className={`w-[100px] h-[30px] flex items-center justify-center ${type == "Final" ? 'bg-[#E0E7FF]' : type === "Partial" ? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
          {type}
        </div>
      }
    }
  ]


  return (
    <div className="flex flex-col gap-3">
      <h6 className="font-semibold text-[20px]">Changes</h6>

      <Table columns={columns} data={data} />
    </div>
  );
}
