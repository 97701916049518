import React from "react";
import Table from "../table/table.jsx";

import { DynamicTable } from "../table/tableWithTotal.jsx";

// const tempData = [
//   {
//     user_id: 'krishna',
//     user_name:'test',
//     phone:'1224543423',
//     email:'test@email.com'
//   }
// ]

const CompletedSearchResults = ({ data, display }) => {
  const columns = [
    {
      field: "reviewer",
      headerName: "Reviewer",
    },
     {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      width:"200px"
    },
    {
      field: "Name",
      headerName: "CA",
    },
    {
      field: "Name",
      headerName: "#Nights",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate:"sum",
amount:true

    }
  ];
  const columns2 = [
   
    {
      field: "trip_id",
      headerName: "Review ID",
    },
    {
      field: "rev_dates",
      headerName: "Review Dates",
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
      width:"200px"
    },
    {
      field: "Name",
      headerName: "CA",
      totalLabel:"Review Total"
    },
    {
      field: "Name",
      headerName: "#Nights",
    },
    {
      field: "estimated_amount",
      headerName: "Estimated",
      aggregate:"sum",
amount:true
    }
  ];
 //data = tempData;

 if(display && display == 'review'){
  return <DynamicTable columns={columns2} data={data} groupByField="trip_id"/> 
}else if(display && display == 'reviewer'){
  return <Table columns={columns} data={data} />; 
}else{
  return ''
}
  
};

export default CompletedSearchResults;
