/* eslint-disable react/prop-types */
import React from 'react'
import dayjs from 'dayjs';

  function ReviewInfoView(reviewInfoData) {
  
    const modifyDate =(dateRes) => {
        if(dateRes && dateRes !== "Invalid Date"){
          return dateRes
        }else{
          return ""
        }
    }
    const getModifiedData = (str) => {
        return str && str != null && str != undefined ? str : ""
    }

   const RRCName =  getModifiedData(reviewInfoData?.reviewInfoData?.rrc_title) + ' '+ getModifiedData(reviewInfoData?.reviewInfoData?.rrc_firstname) + ' '+ getModifiedData(reviewInfoData?.reviewInfoData?.rrc_middename )+' '+getModifiedData(reviewInfoData?.reviewInfoData?.rrc_lastname)
  
  
    return (
    <div className='text-[14px]'>
         <div className="flex flex-col gap-4 justify-between py-4 text-[14px]">
      <div className="flex flex-row gap-8 px-20 text-left">
        <div className="w-60">
          <div>Grantee Name</div>
          <div>{reviewInfoData?.reviewInfoData?.Name ? reviewInfoData?.reviewInfoData?.Name : ""}</div>
        </div>
        <div className="w-60">
          <div>Review Dates</div>
          <div className='flex gap-1'>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.MeetingDate).format("MM/DD/YYYY"))}</div>
          <div>-</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.ExitDate).format("MM/DD/YYYY"))}</div>
          </div>
          
        </div>
        <div className="w-60">
          <div>Review Type</div>
          <button type="button" className="bg-indigo-600/20  text-indigo-600 py-2 px-3 text-[16px] rounded-full"><div>{reviewInfoData?.reviewInfoData?.review_type}</div></button>
        </div>
        <div className="w-60">
          <div>Review SubType</div>
          <div>{reviewInfoData?.reviewInfoData?.sub_type ? reviewInfoData?.reviewInfoData?.sub_type : ""}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-20 text-left">
        <div className="w-60">
          <div>RRC</div>
          <div>{getModifiedData(RRCName)}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-20 text-left">
        <div className="w-60">
          <div>RTL</div>
          <div>{getModifiedData(reviewInfoData?.reviewInfoData?.TITLE)+" "+getModifiedData(reviewInfoData?.reviewInfoData?.FName)+" "+getModifiedData(reviewInfoData?.reviewInfoData?.LName)} </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-20 text-left">
        <div className="w-60">
          <div>Grantee Program Specialist</div>
          <div>{reviewInfoData?.reviewInfoData?.PS_TITLE && reviewInfoData?.reviewInfoData?.PS_TITLE+" "+
          reviewInfoData?.reviewInfoData?.PS_FName && reviewInfoData?.reviewInfoData?.PS_FName+" "+
          reviewInfoData?.reviewInfoData?.PS_LName && reviewInfoData?.reviewInfoData?.PS_LName} </div>
        </div>
      </div>
      <div className="flex flex-row gap-8 px-20 text-left">
        <div className="w-60">
          <div>Arrival Date</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.arrival_date).format('MM/DD/YYYY'))}</div>
        </div>
        <div className="w-60">
          <div>Arrival Time</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.arrival_time).format('HH:mm'))}</div>
        </div>
        <div className="w-60">
          <div>Arrival Place</div>
          <div>{getModifiedData(reviewInfoData?.reviewInfoData?.arrival_city)}</div>
        </div>
      </div>
      <div className="flex flex-row gap-8 px-20 text-left">
        <div className="w-60">
          <div>Meeting Date</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.MeetingDate).format('MM/DD/YYYY'))}</div>
        </div>
        <div className="w-60">
          <div>Meeting Time</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.Meetingtime).format('HH:mm'))}</div>
        </div>
        <div className="w-60">
          <div>Meeting Place</div>
          <div>{getModifiedData(reviewInfoData?.reviewInfoData?.MeetingPlace)}</div>
        </div>
      </div>
      <div className="flex flex-row gap-8 px-20 text-left">
        <div className="w-60">
          <div>Exit Date</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.ExitDate).format('MM/DD/YYYY'))}</div>
        </div>
        <div className="w-60">
          <div>Exit Time</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.ExitTime).format('HH:mm'))}</div>
        </div>
        {/* <div className="w-60">
          <div>Exit Place</div>
          <div>{reviewInfoData?.reviewInfoData?.id}</div>
        </div> */}
      </div>
      <div className="flex flex-row gap-8 px-20 text-left">
        <div className="w-60">
          <div>Departure Date</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.departure_date).format('MM/DD/YYYY'))}</div>
        </div>
        <div className="w-60">
          <div>Departure Time</div>
          <div>{modifyDate(dayjs(reviewInfoData?.reviewInfoData?.departure_time).format('HH:mm'))}</div>
        </div>
        <div className="w-60">
          <div>Departure Place</div>
          <div>{getModifiedData(reviewInfoData?.reviewInfoData?.departure_city)}</div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ReviewInfoView
