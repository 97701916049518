import React from 'react'
import TextWithLabel from '../../inputs/text'
import dayjs from 'dayjs'
import { currencyFormatter } from '../../../utils/formatCurrency'

export default function HotelDetailsView({hotelDetails}) {
  return (
    <div className="flex flex-col items-start justify-start gap-8 w-full">
    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Conus Lodging" value={currencyFormatter(hotelDetails?.Lodging)} />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Meals & Incidentals" value={currencyFormatter(hotelDetails?.Meals)}  />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="M&I Days" value={currencyFormatter(hotelDetails?.MIDays)}  />
      </div>
    </div>
    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Booked Rate" value={currencyFormatter(hotelDetails?.Booked_rate)}  />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Taxes" value={currencyFormatter(hotelDetails?.taxes)}  />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Meeting Room" value={currencyFormatter(hotelDetails?.meeting_room)}  />
      </div>
    </div>
    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="First Night" value={dayjs(hotelDetails?.firstnight).format("MM/DD/YYYY")}  />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Last Night" value={dayjs(hotelDetails?.lastnight).format("MM/DD/YYYY")}  />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Number Of Night" value={hotelDetails?.noofnights}  />
      </div>
    </div>
    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Confirmation Number" value={hotelDetails?.confirmNumber}  />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Number Of Rooms" value={hotelDetails?.NoRooms}  />
      </div>
    </div>


{/*     
    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Deposit Required" value="$0.00" />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Estimated Max Penalty" value="$0.00" />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Number Of Rooms" value="1" />
      </div>
    </div>

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Reservation Request Date" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Contract Due Date" value="-" />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Rooming Last Due Date" value="-" />
      </div>
    </div>

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Hotel Contact" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="Maximum Team Size" value="$0.00" />
      </div>
      <div className="w-[200px] text-left">
        <TextWithLabel label="Team Size By Danya" value="0" />
      </div>
    </div>

    <div className="flex gap-8 w-full">
      <div className="w-[200px] text-left">
        <TextWithLabel label="Anticipated Team Size" value="-" />
      </div>

      <div className="w-[200px] text-left">
        <TextWithLabel label="RTRF Team Size" value="0" />
      </div>
    </div>
    <div className="flex gap-8 w-full">
      <div className="w-[500px] text-left">
        <TextWithLabel label="Comments For Hotel Confimation" value="-" />
      </div>
    </div> */}
  </div>
  )
}
