import React from "react";
import TextWithLabel from "../../components/inputs/text";
import PrimaryBox from "../../components/box/box-primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import { useParams } from "react-router-dom";

export default function StatusChangeLogs() {
    const { id, reviewer_id } = useParams();
  return (
    <div className="p-3 bg-white">
        <PrimaryBreadCrumbs
        label="Reviewer Status"
        path={`/reviews/reviewer-status/${id}/${reviewer_id}`}
      />

      <div className="mt-3">
        <h4 className="font-bold">Change Form</h4>
      </div>
      <div className="flex flex-col gap-3 text-left mt-10">
        <div className="flex gap-3">
          <TextWithLabel className="w-[200px]" label="Traveler" value=" Dr Catherinen Cowell" />
          <TextWithLabel className="w-[200px]" label="Daynya ID" value="000-00-8928" />
          <TextWithLabel className="w-[200px]" label="Phone" value="(212)234-345(Home)" />
        </div>
        <div className="flex gap-3">
          <TextWithLabel className="" label="Review ID/Grantee" value="022NY003/Cypress Hill Child Care Corporation" />
          
        </div>
        <TextWithLabel className="w-[250px]" label="Review Dates" value="11/22/2009 - 11/23/2010" />

        <div className="border border-gray-500">
            <PrimaryBox>
                <div className="flex">
                <TextWithLabel className="w-[200px]" label="Date" value="11/22/2009 - 11/23/2010" />
                <TextWithLabel className="w-[100px]" label="Reason" value="Change" />
                <TextWithLabel className="min-w-[100px]" label="Description" value="Demo" />
                <TextWithLabel className="w-[200px]" label="Entered By" value="Krishna Shetty" />
                </div>
            </PrimaryBox>
        </div>
        <div className="flex">
            <div>
                <TextWithLabel className="w-[200px]" label="Ticket Owed to Danya" value="No" />
            </div>
            <div className="flex flex-col gap-3">
                <div className="flex">
                    <TextWithLabel className="w-[200px]" label="Check Owed to Danya" value="No" />
                    <TextWithLabel className="w-[200px]" label="Check Amount" value="$0.00" />
                </div>
                <div className="flex">
                    <TextWithLabel className="w-[200px]" label="Date Rec'd Check" value="" />
                    <TextWithLabel className="w-[200px]" label="Personal Check" value="" />
                </div>
                <div className="flex">
                    <TextWithLabel className="w-[200px]" label="Dayna Check" value="" />
                    <TextWithLabel className="w-[200px]" label="Other Check" value="" />
                </div>
                <div className="flex">
                    <TextWithLabel className="w-[200px]" label="Amount" value="$0.00" />
                    <TextWithLabel className="w-[200px]" label="Acctg" value="" />
                </div>
            </div>
        </div>

      </div>
    </div>
  );
}
