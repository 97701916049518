import React from "react";
import Input from "../../../../inputs/input";

export default function LocalPOV({ data, errors }) {
  console.log("errors", data, errors)
  return (
    <div className="flex flex-col gap-3 p-3 text-left">
      <div className="w-2/6 flex gap-3">
        <div className="w-3/6">
          <Input
            label="M&I Days"
            name="mi_days"
            id="mi_days"
            value={data?.mi_days}
          />
        </div>
        <div className="w-3/6">
          <Input
            label="Meals ($)"
            name="meals"
            id="meals"
            value={data?.meals}
            isErrorOccurred={errors.meals}
          />
        </div>
      </div>
      <div className="w-2/6">
        <Input type="textArea" label="AirFare Desc" />
      </div>
    </div>
  );
}
