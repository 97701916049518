import React, { useEffect, useState } from "react";
import Search from "../../inputs/search";
import NewReviews from "./new-reviews";
import Changes from "./changes";
import { get } from "../../../services";
import Others from "./others";
import NotSubmitted from "./not-submitted";

export default function ProjectInbox() {
  const [newReviewData, setNewReviewData] = useState([]);
  const [otherReviewData, setOtherReviewData] = useState([]);
  const [notSubmittedReviews, setNotSubmittedReviews] = useState([]);
  const [reviewChanges, setReviewChanges] = useState([]);
  useEffect(() => {
    get('projectInbox/getProjectInbox?inboxType=NewReview').then(res => {
      if (res) {
        console.log('success', res);
        setNewReviewData(res);
      }
    }).catch(err => {
      console.error(err)
    })
    get('projectInbox/getProjectInbox?inboxType=Other').then(res => {
      if (res) {
        console.log('success', res);
        setOtherReviewData(res);
      }
    }).catch(err => {
      console.error(err)
    })
    get('projectInbox/getProjectInbox?inboxType=NotSubmitted').then(res => {
      if (res) {
        console.log('success', res);
        setNotSubmittedReviews(res);
      }
    }).catch(err => {
      console.error(err)
    })
    get('projectInbox/getProjectInbox?inboxType=AddOn').then(res => {
      if (res) {
        console.log('success', res);
        setReviewChanges(res);
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])

  return (
    <div className="flex flex-col text-left gap-3">
      <div className="flex flex-col gap-6 mt-6">
        <h6 className="font-semibold text-[20px]">Project Inbox</h6>
        <div className="w-[400px]">
          <Search searchLabel="Search" />
        </div>
      </div>
      <NewReviews data={newReviewData} />
      <Changes data={reviewChanges} />
      <Others data={otherReviewData} />
      <NotSubmitted data={notSubmittedReviews} />

    </div>
  );
}
