import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import TitlePrimary from "../../components/typhography/title-primary";
import TextWithLabel from "../../components/inputs/text";
import ReconcBillSearchResults from "../../components/hotel-reconciliation/hotel-bill-reonc";
import { get, post } from "../../services";
import { useStore } from "../../store";
import LayoutSecondary from "../../components/layouts/secondary";

export default function HotelBillReconciiliationReviewersList() {

  const { ssoUserID } = useStore();
  const { id } = useParams();
  const [hotelData, setHotelData] = useState({});
  const [reviewId, setReviewId] = useState("");
  const [reviewerListData, setReviewerListData] = useState([]);

  useEffect(() => {
    get(`hotelReconsile/details?tripHotelUid=${id}`).then(res => {
      console.log(res, 'res')
      if (res) {
        setHotelData(() => {
          var d = { ...res[0] }
          return d
        })
      }
    }).catch(err => {
      console.error(err)
    })
    get(`hotelReconsile/getReviewId?tripHotelUid=${id}`).then(res => {
      if (res) {
        setReviewId(res[0]?.trip_id);
      }
    }).catch(err => {
      console.error(err)
    })
    const dataObj = {
      tripHotelUid: id,
      userId: ssoUserID
    }
    post(`hotelReconsile/hotelReconsileRecHotelReviewerList`, dataObj).then(res => {
      console.log("res as follows", res);
      setReviewerListData(res);
    }).catch(err => {
      console.error(err)
    })
  }, [id])

  return (
    <LayoutSecondary
    bCrumpLabel={"Search"}
    bCrumpPath={"/hotel-reconciliation"}
    title="Reviewers List"
  >
    <div className="text-left pb-10">
      {/* <TitlePrimary title={`Reviewers List`} /> */}
      <div className="">
        {/* <h6 className="font-bold">{hotelData?.Name}</h6> */}
        <div className="flex  gap-3 bg-white rounded-sm p-2 shadow-md mt-5">
          <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
          <TextWithLabel label="" value={hotelData?.Name} />
            <TextWithLabel label="" value={hotelData?.Address} />
            
            </div>
            <div className="mt-5">
              <span> 
              {hotelData?.address2 && <span><TextWithLabel label="" value={hotelData?.address2} />, </span> }
                <TextWithLabel label="" value={hotelData?.Zip} />
                </span>
                <TextWithLabel label="" value={hotelData?.Phone} />
              
            </div>
          </div>
          <div className="ml-auto flex gap-3 w-4/6">
            <div className="flex flex-col gap-2">
              <TextWithLabel label="Booked Rate" value={hotelData?.BookedRate} />
              <TextWithLabel label="Taxes" value={hotelData?.Taxes} />
              <TextWithLabel label="Meeting Room" value={hotelData?.meeting_room} />
              <TextWithLabel label="Reconciled On" value={hotelData?.rec_date} />
            </div>
            <div className="flex flex-col gap-2">
              <TextWithLabel label="Hotel Nights" value={hotelData?.hotel_nights} />
              <TextWithLabel label="Direct Billing" value="Yes" />
              <TextWithLabel label="Trip ID" value={reviewId} />
              <TextWithLabel label="Reconciled By" value={hotelData?.rev_name} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 text-left mt-5">
        <ReconcBillSearchResults data={reviewerListData} />
      </div>
    </div>
    </LayoutSecondary>
  );
}
