import React, { useState } from "react";
import TertiaryButton from "../../../buttons/tertiary";
import Input from "../../../inputs/input";
import PrimaryButton from "../../../buttons/primary";
import ReviewersTable from "./reviewers-table";
import SearchResultsTable from "./search-results";
import { post } from "../../../../services";
import { useStore } from "../../../../store";

import { toast, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from '../../../loader'

export default function ReviewersTab({ reviewInfo, id, tripUID }) {
  const navigate = useNavigate()
  const { ssoUserID } = useStore()
  console.log(tripUID, 'trupUId')
  const [searchResults, setSearchResults] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [loading, setLoading] = useState(false)

  const isConfirmEnabled =
    searchResults && searchResults.length > 0
      ? searchResults.filter((d) => d.selected).length > 0
        ? false
        : true
      : true;

  const handleCheckBox = (e) => {
    //setSearchResults(data);

    if (e.target.id) {
      setSearchResults(
        searchResults.map((d, i) => {
          if (i == e.target.id) {
            d["selected"] = e.target.checked;
          } else {
            d["selected"] = false;
          }

          return d;
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(searchData);
    setLoading(true)
    if (searchData && (searchData.Fname || searchData.Lname || searchData.id)) {
      const tempData = {
        trip_id: id,
        id: searchData.id || "",
        Fname: searchData.Fname || "",
        Lname: searchData.Lname || ""
      };
  
      post("reviews/add_search_for_reviewer", {...tempData, "userId": ssoUserID})
        .then((res) => {
          console.log(res);
          if (res) {
            if(res.length <=0){
              toast.error('No Data Found', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            }
            setSearchResults(res);
          }
          setLoading(false)
        })
        .catch((err) => {
          console.error(err.response);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          setLoading(false)
        });
    }
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setSearchData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setSearchData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const confirmSearchResult = () => {
    const filterSelectedItem = searchResults.filter((d) => d.selected);

    if (!isConfirmEnabled && filterSelectedItem.length > 0) {
      post("reviews/add_reviewers_to_team", {
        reviewers_uid: filterSelectedItem[0].reviewers_uid,
        trip_uid: tripUID,
        hon_days: "0",
        user_code:ssoUserID,
        "userId": ssoUserID
      })
        .then((res) => {
          console.log(res);
          if (res) {
            toast.success('Success', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            setTimeout(() => {
              window.location.reload();
            }, 100);
            
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          
        });
    }
  };

  const handleChangeReviewer = () => {
    navigate(`/reviews/reviewer-status/all/${id}`)
  }

 

  return (
    <div className="pb-10 text-left flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        <h6 className="text-[#030712] font-[600] text-[24px]">Reviewers</h6>
        <div>
          <ReviewersTable reviewInfo={reviewInfo?.reviewerInfo} reviewid={id}/>
        </div>
        <div>
          <TertiaryButton
            label="Change Reviewer Status"
            className={"px-3 py-2"}
            type="button"
            onClick={handleChangeReviewer}
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h6 className="text-[#030712] font-[600] text-[20px]">Reviewers</h6>
        <form
          className="flex flex-col gap-3"
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <div className="flex gap-3">
            <div className="w-[192px]">
              <Input label="Reviewer ID" id="" name="id" />
            </div>
            <div className="w-[192px]">
              <Input label="First Name" id="" name="Fname" />
            </div>
            <div className="w-[192px]">
              <Input label="Last Name" id="" name="Lname" />
            </div>
          </div>
          <PrimaryButton
            type="submit"
            label="Search"
            className={"px-3 py-2 w-[100px]"}
          />
        </form>

        <h6 className="text-[#030712] font-[600] text-[18px]">
          Search Results ({searchResults.length})
        </h6>
        {
          loading  ? <Loader /> :  <div>
          <SearchResultsTable
            data={searchResults}
            handleCheckBox={handleCheckBox}
          />
        </div>
        }
       
        <PrimaryButton
          disabled={isConfirmEnabled}
          label="Add Reviewer"
          type="button"
          className={"px-2 py-2 w-[150px]"}
          onClick={confirmSearchResult}
        />
      </div>
    </div>
  );
}
