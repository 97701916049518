import React, { useState, useEffect } from "react";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";
import Select from "../../components/inputs/select";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import { get, post } from "../../../src/services";
import { toast, Bounce } from "react-toastify";
import TelePhone from "../../components/inputs/telephone";
import { useStore } from "../../store";

const contentAreas = [
  {
    label: "ECDC",
    name: "chilDevelopment",
    value: "chilDevelopment",
  },
  {
    label: "FIS",
    value: "FIS",
  },
  {
    label: "ISR1",
    value: "ISR1",
  },
  {
    label: "ISR2",
    value: "ISR2",
  },
  {
    label: "ERSEA",
    value: "Ersea",
  },
  {
    label: "PDM",
    value: "Pdm",
  },
  {
    label: "Head Start On Site Monitoring Protocol",
    value: "Hssmp",
  },
  {
    label: "Class",
    value: "Class",
  },
  {
    label: "New Reviewer",
    value: "Newreviewer",
  },
  {
    label: "The 5 Stages of the Monitoring",
    value: "Five stages of monitoring",
  },
  {
    label: "DIS/MH",
    value: "dis",
  },
  {
    label: "HEA/NUT/SAF/TRANS",
    value: "Hea",
  },
  {
    label: "OHS Monitoring Software",
    value: "Ohs",
  },
  {
    label: "Monitoring Overview",
    value: "Monitorover",
  },
  {
    label: "Introducing to Monitoring in Fy 2011",
    value: "Im",
  },
  {
    label: "Roles and Responsibilities in Fy 2011",
    value: "Roles",
  },
  {
    label: "FCP",
    value: "Fcp",
  },
  {
    label: "RC",
    value: "rc",
  },
  {
    label: "PRISM",
    value: "prism",
  },
  {
    label: "Willing to bring Personal Computer",
    value: "Personal",
    name: "Personal",
  },
];

const AddReviewer = () => {
  const navigate = useNavigate();
  const { ssoUserID } = useStore();

  // Define state for form fields
  const [reviewer, setReviewer] = useState({
    title: "",
    fName: "",
    mInitial: "",
    lName: "",
    suffix: "",
    homeAddress1: "",
    homeAddress2: "",
    homeCity: "",
    homeState: "",
    homeZip: "",
    homePhone: "",
    homeFax: "",
    cellularPhone: "",
    fedexAddress1: "",
    fedexAddress2: "",
    fedexCity: "",
    fedexState: "",
    fedexZip: "",
    workAddress1: "",
    workAddress2: "",
    workAddress3: "",
    workCity: "",
    workState: "",
    workZip: "",
    workPhone: "",
    workFax: "",
    chilDevelopment: 0,
    disabilities: 0,
    earlyHeadStart: 0,
    familyCommunity: 0,
    fiscalManagement: 0,
    fiscalApproved: 0,
    health: 0,
    mentalHealth: 0,
    nutrition: 0,
    programDesign: 0,
    bilingual: 0,
    reportWriters: 0,
    reportCoordinatorAdmin: 0,
    personalComputers: 0,
    outcomesTrained: 0,
    airport: "",
    airport2: "",
    region01: 0,
    region02: 0,
    region03: 0,
    region04: 0,
    region05: 0,
    region06: 0,
    region07: 0,
    region08: 0,
    region09: 0,
    region10: 0,
    region11: 0,
    region12: 0,
    email: "",
    userId: "",
    reviewerType: ""
  });

  const [regions, setRegions] = useState({
    region01: 0,
    region02: 0,
    region03: 0,
    region04: 0,
    region05: 0,
    region06: 0,
    region07: 0,
    region08: 0,
    region09: 0,
    region10: 0,
    region11: 0,
    region12: 0,
  })

  const [selectedServiceArea, setSelectedServiceArea] = useState({
    chilDevelopment: 0,
    health: 0,
    disabilities: 0,
    programDesign: 0,
    mentalHealth: 0,
    bilingual: 0,
    Personal: 0,
  })
  const [reviewTypes, setReviewTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorFields, setErrorFields] = useState({});

  // Fetch reviewer types from API
  useEffect(() => {
    get(`reviewers/getReviewersReviewType`)
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const formattedTypes = data.map((item) => ({
            label: item.Reviewer_Type,
            value: item.Reviewer_Type,
          }));
          setReviewTypes(formattedTypes);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const handleServiceAreaChange = (e, value) => {
    const { checked } = e.target;
    setSelectedServiceArea((prevState) => ({
      ...prevState,
      [value]: checked ? 1 : 0, // Update reviewerType in state
    }));
  };

  const handleRegionChange = (e) => {
    const { name, checked } = e.target;
    setRegions((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0, // Update reviewerType in state
    }));
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setReviewer((prevState) => ({
      ...prevState,
      reviewerType: selectedValue, // Update reviewerType in state
    }));
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReviewer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      ...reviewer,
      ...regions,
      ...selectedServiceArea,
    }
    const errorMapFields = {
      'fName': 'First Name',
      'lName': 'Last Name',
      'homeAddress1': 'Home Address 1',
      'homeCity': 'Home City',
      'homeState': 'Home State',
      'homeZip': 'Home Zip',
      'homePhone': 'Home Phone',
      'homeFax': 'Home Fax',
      'cellularPhone': 'Cellular Phone',
      'workPhone': 'Work Phone',
      'workFax': 'Work Fax'
    }
    const errorObj = {}
    Object.keys(reviewer).map((item) => {
      if (['fName', 'lName', 'homeAddress1', 'homeCity', 'homeState', 'homeZip', 'homePhone'].indexOf(item) !== -1) {
        if (!reviewer[item] || reviewer[item].trim().length === 0) {
          errorObj[item] = `Error - ${errorMapFields[item]} is required`
        }
      }
      /*if (['homePhone', 'homeFax', 'cellularPhone', 'workPhone', 'workFax'].indexOf(item) !== -1) {

        if (reviewer[item] !== null && reviewer[item].trim().length > 0) {
          //eslint no-useless-escape: "error"//
          const phoneRegex = /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)(\d{3})[-.\s]?(\d{4})$/;
          if (phoneRegex.test(reviewer[item])) {
            errorObj[item] = `Error - ${errorMapFields[item]} contains non-numeric characters`
          }
        }
      }*/
    })
    setErrorFields(errorObj);
    if (Object.keys(errorObj).length > 0) {
      return;
    }
    // Post the form data to the API
    post("reviewers/addReviewer/", { ...formData, "userId": ssoUserID })
      .then((res) => {

        console.log("Form submitted successfully:", res);
        if (res) {
          toast.success('Added Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });

          setTimeout(() => {
            navigate("/reviewers")
          }, 300);
        }
      })
      .catch((err) => {
        console.error("Error submitting form:", err);
        toast.error('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  return (
    <form onChange={handleChange} onSubmit={handleSubmit}>
      <div>
        <PrimaryBreadCrumbs label="Reviewer Search" path="/reviewers" />
      </div>
      <div className="flex flex-col gap-3 text-left my-10 bg-white shadow-sm p-3">
        <div>
          <TextWithLabel label="Danya ID" value="-------" />
        </div>
        <div className="flex gap-3">
          <Input
            label="Title"
            name="title"
            value={reviewer.title}
            className="w-[100px]"
          />
          <Input
            label="First"
            name="fName"
            value={reviewer.fName}
            required
            isErrorOccurred={errorFields?.fName}
          />
          <Input
            label="MI"
            name="mi"
            value={reviewer.mInitial}
            className="w-[100px]"
          />
          <Input label="Last" name="lName" value={reviewer.lName} required isErrorOccurred={errorFields?.lName} />
          <Input
            label="Suffix"
            name="suffix"
            value={reviewer.suffix}
            className="w-[100px]"
          />
        </div>
        <div className="w-4/6">
          <Input label="Company Name" value="" />
        </div>
        <div className="w-3/6 flex flex-col gap-3">
          <Input label="Home Address 1" name="homeAddress1" value={reviewer.homeAddress1} required isErrorOccurred={errorFields?.homeAddress1} />
          <Input label="Home Address 2" name="homeAddress2" value={reviewer.homeAddress2} />
          <div className="flex gap-3">
            <Input label="City" name="homeCity" value={reviewer.homeCity} required isErrorOccurred={errorFields?.homeCity} />
            <Input label="State" name="homeState" value={reviewer.homeState} required isErrorOccurred={errorFields?.homeState} />
            <Input label="Zip Code" name="homeZip" value={reviewer.homeZip} className="w-[100px]" required isErrorOccurred={errorFields?.homeZip} />
          </div>
          <div className="w-3/6 flex gap-3">
            <TelePhone label="Home Phone" name="homePhone" value={reviewer.homePhone} className="w-[300px]" required isErrorOccurred={errorFields?.homePhone} />
            <Input label="Home Fax" name="homeFax" value={reviewer.homeFax} className="w-[300px]" isErrorOccurred={errorFields?.homeFax} />
            <TelePhone label="Cellular" name="cellularPhone" value={reviewer.cellularPhone} className="w-[300px]" isErrorOccurred={errorFields?.cellularPhone} />
          </div>
        </div>

        <div className="w-3/6 flex gap-3">
          <Input label="Email Address" name="email" value={reviewer.email} className="w-[300px]" />
        </div>
        {!loading && (
          <div className="w-3/6 flex gap-3">
            <Select
              options={reviewTypes}
              label="Reviewer Type"
              className="w-[300px]"
              value={reviewer.reviewerType}
              onChange={handleSelectChange}
            />
          </div>
        )}
        <div className="w-3/6 flex gap-3">
          <Input label="Employer Name" value="" className="w-[400px]" />
        </div>
        <div className="w-3/6 flex flex-col gap-3">
          <Input label="Work Address 1" name="workAddress1" value={reviewer.workAddress1} />
          <Input label="Work Address 2" name="workAddress2" value={reviewer.workAddress2} />
          <div className="flex gap-3">
            <Input label="City" name="workCity" value={reviewer.workCity} />
            <Input label="State" name="workState" value={reviewer.State} className="w-[100px]" />
            <Input label="Zip Code" name="workZip" value={reviewer.workZip} className="w-[100px]" />
          </div>
          <TelePhone label="Work Phone" name="workPhone" value={reviewer.workPhone} className="w-[300px]" isErrorOccurred={errorFields?.workPhone} />
          <TelePhone label="Work Fax" name="workFax" value={reviewer.workFax} className="w-[300px]" isErrorOccurred={errorFields?.workFax} />
        </div>
        <div className="w-3/6 flex flex-col gap-3">
          <Input label="FedEx Address 1" name="fedexAddress1" value={reviewer.fedexAddress1} />
          <Input label="FedEx Address 2" name="fedexAddress2" value={reviewer.fedexAddress2} />
          <div className="flex gap-3">
            <Input label="City" name="fedexCity" value={reviewer.fedexCity} />
            <Input label="State" name="fedexState" value={reviewer.fedexState} className="w-[100px]" />
            <Input label="Zip Code" name="fedexZip" value={reviewer.fedexZip} className="w-[100px]" />
          </div>
        </div>
        <div className="w-3/6 flex flex-col gap-3">
          <Input label="Airport" name="airport" value={reviewer.airport} />
        </div>
        <div className="w-full">
          <CheckBoxGroup label="Service Area" groups={contentAreas.map((area) => ({
            label: area.label,
            value: area.value, // The value will be "childDevelopment"
            name: area.name,
            checked: selectedServiceArea[area.value] === 1, // Checked if set to 1
          }))}
            handleChange={handleServiceAreaChange}
          />
        </div>
        <div className="w-4/6">
          <CheckBoxGroup
            label="Regions"
            groups={[...Array(12)].map((_, i) => {
              const regionNumber = (i < 9 ? "0" : "") + `${i + 1}`;
              return {
                label: regionNumber,
                value: regionNumber,
                name: `region${regionNumber}`, // Name corresponds to the region key
                checked: regions[`region${regionNumber}`] === 1, // Checked if value is 1
              };
            })}
            handleChange={handleRegionChange} // Handle change
          />
        </div>
        <div className="flex gap-2 my-10">
          <SecondaryButton
            className={"py-3 px-10"}
            onClick={() => navigate("/reviewers")}
            label={"Cancel"}
          />
          <PrimaryButton
            type="submit"
            className={"py-3 px-10"}
            label={"Update"}
          // onClick={() => navigate("/reviewers")}
          />{" "}
        </div>
      </div>
    </form>
  );
};

export default AddReviewer;
