import React, {useState, useEffect} from "react";
import LayoutSecondary from "../../components/layouts/secondary";
import Table from "../../components/table/table";
import CheckBox from "../../components/inputs/checkbox";
import PrimaryButton from "../../components/buttons/primary";
import TertiaryButton from "../../components/buttons/tertiary";
import { get, post } from "../../services";

import { toast, Bounce } from "react-toastify";
import { useStore } from "../../store";

export default function UploadAirTicketItinerary() {
  
  const { ssoUserID } = useStore();

  const [data, setData] = useState([])
  const [summary, setSummary] = useState([])
  const [isVerified, setVerified] = useState(false)
  //const [error, setError] = useState(flase)

  useEffect(() => {
    get(`upload/air_itinerary_status_display`).then(res => {
      if(res && res.length > 0){
        setData(res)
      }
    }).catch(err => {
      console.error(err)
    })
    get(`upload/air_summary`).then(res => {
      if(res && res.length > 0){
        var tempTickets = {label:'Tickets $'}
        var tempFee = {label:'Fee $'}
        res.forEach(d => {
          tempTickets["nos"] = d.NOOFTICKETS;
          tempTickets["amount"] = d.TICKET_AMOUNT;
          tempFee["nos"] = d.NOOFFEE;
          tempFee["amount"] = d.FEE_AMOUNT;

        })
        setSummary([tempTickets, tempFee])
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])
  

  const columns = [
    {
      field: "trip_id",
      headerName: "Review ID",
      url: "/uploads/upload-air-ticket-itn",
      urlParams: ["Upload_air_uid"],
    },
    {
      field: "passenger_name",
      headerName: "Reviewer",
      cellRenderer : ({data}) => {
        var name = '';

        
        if(data.fname){
          name = data.fname;
        }
        if(data.lname){
          name += " " + data.lname;
        }

        return <span>{name}</span>
      }
    },
    {
      field: "ticket_number",
      headerName: "Ticket Number",
    },
    {
      field: "departDate",
      headerName: "Depart Date",
      type:'date',
      time:"off"

    },
    {
      field: "arrivalDate",
      headerName: "Arrival Date",
      type:'date',
      time:"off"
    },
    {
      field: "ticket_amount",
      headerName: "Ticket Amount",
      amount:true
    },
    {
      field: "feeAmount",
      headerName: "Fee Amount",
      amount:true
    },
    {
      field: "message_warning",
      headerName: "Message/Warning",
      autoHeight:true,
      wrapText:true,
      cellRenderer:({value}) => {
        return <div dangerouslySetInnerHTML={{__html: value}}></div>
      }
    },
  ];
  // const data = [
  //   {
  //     review_id: "71627182",
  //     ticket_amount: "200",
  //     reviewer: "Test",
  //     ticket: "92875263789",
  //   },
  // ];

  const column1 = [
    {
        field: "label",
        headerName: "Transaction Type",
        type:'action'
      },
      {
        field: "nos",
        headerName: "Number Of Transactions",
        type:'action'
      },
      {
        field: "amount",
        headerName: "Transaction Amount",
        type:'action',
        amount:true
      },
  ]

  // const data1 = [
  //   {
  //       type:'Ticket(s)',
  //       nos:'1',
  //       amount:'$15'
  //   }
  // ]
  const handleChange = (e) => {
    setVerified(e.target.checked)
  }
  const submit = () => {
    if(isVerified){
      post(`upload/air_upload_air_tickets_itinerary`, {"input_user_id": ssoUserID, "userId": ssoUserID }).then(res => {
        if(res){
          window.location.reload()
        }
    }).catch(err => {
      console.error(err)
    })
    }else{
      toast.error('Kindly verify by clicking the checkbox', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }
  return (
    <div>
      <LayoutSecondary
        bCrumpLabel={"Uploads"}
        bCrumpPath={"/uploads"}
        title="Upload Air Ticket Itinerary"
      >
        <div className="flex flex-col gap-8 mb-20">
          <div>
            <Table data={data} columns={columns} />
          </div>
          <div className="flex flex-col gap-8">
            <h6>Summary</h6>
            <Table data={summary} columns={column1} filter={false}  pagination={false}/>
          </div>
          <div>
                <CheckBox onChange={(e) => handleChange(e)} label="I have verified the input" name="verified"  id="verified"/>
          </div>
          <div className="flex gap-6">
                <TertiaryButton label="Refresh" className="p-2" onClick={() => window.location.reload()}/>
                <PrimaryButton label="Upload Air Tickets Itinerary" className="p-2" onClick={() => submit()}/>
          </div>
        </div>
      </LayoutSecondary>
    </div>
  );
}
