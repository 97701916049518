import React, { useState, useEffect } from "react";
import Input from "../../../../inputs/input";
import Select from "../../../../inputs/select";
import { get } from "../../../../../services";

export default function TravelInfo({ data, id, errors }) {

  const [travelModes, setTravelModes] = useState([])
  const [carSizes, setCarSizes] = useState([])
  const [rentalAgencies, setRentalAgencies] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Promise.all([
      get(`lookup/tis_travelmode?review_id=${id}`),
      get(`lookup/tis_carsize?review_id=${id}`), //add program sp here
      get(`lookup/tis_rentalagency?review_id=${id}`),
    ])
      .then(([travel, car, rental]) => {

        if (travel instanceof Array && travel.length > 0) {
          setTravelModes(travel.map(d => {
            return {
              label: d.TRAVELMODEDesc,
              value: d.TRAVELMODE
            }
          }))
        }
        if (car instanceof Array && car.length > 0) {
          setCarSizes(car.map(d => {
            return {
              label: d.CarsizeDesc,
              value: d.CarSize
            }
          }))
        }
        if (rental instanceof Array && rental.length > 0) {
          setRentalAgencies(rental.map(d => {
            return {
              label: d.RentalAgencyDesc,
              value: d.RentalAgency
            }
          }))
        }

        setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
      });
  }, []);
  if (loading) {
    return null
  }
  return (
    <div className="flex flex-col gap-3 text-left p-3">
      <div className="w-2/6 flex gap-3">
        <div className="w-full whitespace-nowrap">
          <Select
            label="Travel Mode"
            name="TravelMode"
            id="TravelMode"
            selected={data?.TravelMode}
            options={travelModes}
            required
            isErrorOccurred={errors?.TravelMode}
          />
        </div>
        <div className="w-full">
          <Input label="Fare ($)"
            name="Airfare"
            id="Airfare"
            value={data?.Airfare}
            isErrorOccurred={errors.Airfare}
          />
        </div>
      </div>
      <div className="w-2/6 flex gap-3">
        <div className="w-3/6">
          <Input
            label="POV Miles"
            name="POV_Miles"
            id="POV_Miles"
            value={data?.POV_Miles}
            isErrorOccurred={errors.POV_Miles}
          />
        </div>
        <div className="w-3/6">
          <Input label="Rental Car ($)"
            name="Rental_Car"
            id="Rental_Car"
            value={data?.Rental_Car}
            isErrorOccurred={errors.Rental_Car}
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="w-2/6">
          <Select
            label="Car Size"
            name="CarSize"
            id="CarSize"
            selected={data?.CarSize}
            options={carSizes}
            isErrorOccurred={errors?.CarSize}
          />
        </div>
        <div className="w-2/6">
          <Select
            label="Rental Agency"
            name="RentalAgency"
            id="RentalAgency"
            selected={data?.RentalAgency}
            options={rentalAgencies}
            isErrorOccurred={errors?.RentalAgency}
          />
        </div>
      </div>

      <div className="w-full">
        <Input
          type="textArea"
          name="RentalAgency"
          id="RentalAgency"
          label="Other Rental Agency"
        />
      </div>
    </div>
  );
}
