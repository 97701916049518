import React from "react";
import InputDate from "../../../../inputs/date";
import Input from "../../../../inputs/input";
import dayjs from "dayjs";

export default function TravelAgencyTISTab({ data, errors }) {
  console.log("TISTRavel", errors);
  return (
    <div className="flex flex-col gap-3 text-left p-3">
      <div className="flex gap-3">
        <div className="w-3/6">
          <div className="w-4/6">
            <InputDate name="Arrival_date" id="Arrival_date" label="Required Arrival date At Grantee Location"
              defaultValue={dayjs(data?.Arrival_date).format("YYYY-MM-DD")}
              required
              isErrorOccurred={errors.Arrival_date}
            />
          </div>
        </div>
        <div className="w-3/6">
          <div className="w-4/6">
            <Input name="Arrival_city" id="Arrival_city" value={data?.Arrival_city} label="City" />
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-3/6">
          <div className="w-4/6">
            <InputDate name="Departure_date" id="Departure_date" label="Required Departure from Grantee"
              defaultValue={dayjs(data?.Departure_date).format("YYYY-MM-DD")}
              required
              isErrorOccurred={errors.Departure_date}
            />
          </div>
        </div>
        <div className="w-3/6">
          <div className="w-4/6">
            <Input name="departure_city" id="departure_city" value={data?.departure_city} label="City" />
          </div>
        </div>
      </div>
      <div>
        <h6 className="font-semibold text-[12px] my-3">Reviewers Departure and Return Info</h6>
        <div className="flex gap-3">
          <div className="w-3/6">
            <div className="w-4/6">
              <InputDate name="Depart_Date" id="Depart_Date" label="Departue date"
                defaultValue={dayjs(data?.Depart_Date).format("YYYY-MM-DD")}
                required
                isErrorOccurred={errors.Depart_Date}
              />
            </div>
          </div>
          <div className="w-3/6">
            <div className="w-4/6">
              <InputDate name="Return_Date" id="Return_Date" label="Return Date"
                defaultValue={dayjs(data?.Return_Date).format("YYYY-MM-DD")}
                required
                isErrorOccurred={errors.Return_Date}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Input type="textArea" label="Misc Info" id="MiscInfo" value={data?.MiscInfo} />
      </div>
    </div>
  );
}
