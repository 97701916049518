import React from "react";
import './loader.css'

export default function Loader() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-indigo-600/10">
      <div className="flex justify-center items-center h-screen">
        <div className="relative w-24 h-24 flex justify-center items-center">
          {/* Loading Text */}
          <div className="absolute text-indigo-600 font-semibold text-lg">
            Loading
          </div>
          {/* Rotating Lines */}
          {Array.from({ length: 12 }).map((_, index) => (
            <div
              key={index}
              className="absolute w-2 h-6 bg-indigo-600 rounded-full"
              style={{
                transform: `rotate(${index * 30}deg) translate(0,
-50px)`, // Moves the lines outward
                animation: `fade 1.2s linear infinite`,
                animationDelay: `${(index * 0.1).toFixed(2)}s`,
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
