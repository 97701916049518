import React, { useState } from "react";
import TertiaryButton from "../../buttons/tertiary";
import GranteesView from "../view";
import GranteesEdit from "../edit";
import SecondaryButton from "../../buttons/secondary";
import PrimaryButton from "../../buttons/primary";
import { post } from "../../../services";

import { useStore } from "../../../store";
import PrimaryBox from "../../box/box-primary";
const GranteeViewEditTabDetail = ({granteeSearchData}) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [granteeData, setGranteData] = useState(granteeSearchData)
  const { ssoUserID } = useStore();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(granteeData)
    post('/grantees/', {...granteeData, "userId": ssoUserID}).then(res =>{
      console.log(res)
    }).catch(err => {
      console.error(err)
    })
  }

  const handleChange = (e) => {
    console.log(e.target, 'e.target')
    if(e.target.name && e.target.value){
      setGranteData(prev=>{
        return {...prev, [e.target.name]: e.target.value}
      })
     
    }else if(e.target.name){
      setGranteData(prev=>{
        return {...prev, [e.target.name]: ""}
      }) 
    }
  }

  return (
    <>
      <PrimaryBox >
        <div className="flex flex-col mt-3">

        
        <div className="flex w-full">
        <div className="text-justify font-bold text-xl">
          View a Grantee Program
        </div>
      
        <TertiaryButton onClick={() => setIsEditEnabled(!isEditEnabled)} className={"px-5 py-2 ml-auto"} label={ isEditEnabled ?  "Cancel" :"Edit"} />
        </div>
        <form onChange={handleChange} onSubmit={handleSubmit}>
        {
          isEditEnabled ? <GranteesEdit  granteeSearchData={granteeData}/> : <GranteesView granteeSearchData={granteeSearchData}/>
        }
           {isEditEnabled ? (
        <div className="flex gap-2 mt-3">
          <SecondaryButton className={"py-3 px-10"} onClick={() => setIsEditEnabled(false)} label={"Cancel"} />
          <PrimaryButton className={"py-3 px-10"} label={"Update"} />{" "}
          
        </div>
        
      ) : (
        <></>
      )}
      </form>
      
      </div>  
      </PrimaryBox>
     
    </>
  );
};

export default GranteeViewEditTabDetail;
