import React, { useState, useEffect } from "react";
import PrimaryButton from "../../buttons/primary";
import TertiaryButton from "../../buttons/tertiary";
import PrimaryModal from "../../modals/primary";
import SecondaryButton from "../../buttons/secondary";
// import Select from "../../inputs/select";
// import InputDate from "../../inputs/date";
// import TextWithLabel from "../../inputs/text";
// import TextWithBadge from "../../inputs/text-with-badge";
// import InputToggle from "../../inputs/input-toggle";
import { useNavigate, useParams } from "react-router-dom";
// import CheckBox from "../../inputs/checkbox";
import { get, post } from "../../../services";
// import Input from "../../inputs/input";
import MultiSelect from "../../inputs/multi-select";
import { DynamicTable } from "../../table/tableWithTotal";
import Loader from "../../loader";

import { toast, Bounce } from "react-toastify";
import { useStore } from "../../../store";


const ProcessReviewEditTab = () => {
  const { id } = useParams();
  const { ssoUserID } = useStore();
  const navigate = useNavigate();
  const [reviewers, setReviewers] = useState([]);
  const [reviewersOptions, setReviewersOptions] = useState([]);
  const [selectedReviewers, setSelectedReviwers] = useState([]);
  const [isModalOpened, setModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [cAToogleEnabled, setCAToggleEnabled] = useState(false);
  console.log(reviewers, selectedReviewers, "selectedReviewers");
  const [tableData, setTableData] = useState([]);
  const [editedData, setEditedData] = useState([]);

  useEffect(() => {
    get(`reviews/processReviewProcessReviewerList?tripId=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "res");
          const result = res;
          setReviewers(result);
          setReviewersOptions(
            result.map((d) => {
              var temp = {};
              temp["label"] = d.fname
                //(d.FName ? d.FName : "") + " " + (d.LName ? d.LName : "");
              temp["value"] = d.trip_details_uid;

              return temp;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all(
          selectedReviewers.map(async (d) => {
            const tempReviewer = reviewers.filter(
              (r) => r.trip_details_uid == d
            );
            var tempObj = {
              tripId: id,
              tripDetailsUid: tempReviewer[0]?.trip_details_uid,
            };
            var url = "reviews/processReviewProcessReviewerDetails";
            Object.keys(tempObj).forEach((key, i) => {
              if (i === 0) {
                url += `?${key}=${tempObj[key]}`;
              } else {
                url += `&${key}=${tempObj[key]}`;
              }
            });
            const response = await get(url);
            console.log(response, "resss");
            if (response && response.length > 0) {
              return response[0];
            } else {
              return response;
            }
          })
        );
        setTableData(responses);
        setEditedData(responses)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    if (selectedReviewers && reviewers.length > 0) {
      setLoading(true);
      fetchData();
    }
  }, [selectedReviewers]);
  const updateColumnData = (value, rowIndex, field) => {
    console.log(value, rowIndex, field);
    const updatedData = [...tableData];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [field]: value,
    };
    setEditedData(updatedData);
  };

  const handleUpdate = async() => {
    console.log(editedData, "edited");

    if (selectedReviewers.length > 0) {
      setLoading(true)
      // if (selectedReviewers.length === reviewers.length) {
      //   const temp = {
      //     tripId: id,
      //     tripDetailsUid: 0,
      //     nameofrequestor: ssoUserID,
      //     requestDate: new Date(),
      //     checkNeeded: new Date(),
      //   };
      //   post("reviews/processReviewProcessUpdate", temp)
      //     .then((res) => {
      //       if (res) {
      //         toast.success("Updated All Successfully", {
      //           position: "top-right",
      //           autoClose: 5000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //           draggable: true,
      //           progress: undefined,
      //           theme: "colored",
      //           transition: Bounce,
      //         });
      //       }
      //       setLoading(false)
      //     })
      //     .catch((err) => {
      //       setLoading(false)
      //       console.error(err);
      //       toast.error("Updated All Failed", {
      //         position: "top-right",
      //         autoClose: 5000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "colored",
      //         transition: Bounce,
      //       });
      //     });
      // }else{
        const responses = await Promise.all(
          selectedReviewers.map(async (d) => {
            const tempReviewer = editedData.filter(
              (r) => r.trip_details_uid == d
            );
            var tempObj = {
              tripId: id,
              tripDetailsUid: tempReviewer[0]?.trip_details_uid,
              nameofrequestor: tempReviewer[0]?.Name_of_Requestor ? tempReviewer[0]?.Name_of_Requestor :ssoUserID,
              requestDate: tempReviewer[0]?.Request_Date ?  tempReviewer[0]?.Request_Date : new Date() ,
              checkNeeded: tempReviewer[0]?.Check_Needed ? tempReviewer[0]?.Check_Needed : new Date()
            };
          
            const response = await post('reviews/processReviewProcessUpdate', tempObj);
            console.log(response, "resss");
            if (response) {
              return response;
            } else {
              return response;
            }
          })
        );
        if(responses.length > 0) {
          setLoading(false)
          toast.success("Updated The Items Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          // window.location.reload()
        }else{
          setLoading(false)
          toast.error("Update Items Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      
    }else{
      toast.error("Nothing to update", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const handleUpdateProcess = async() => {
    
    if (selectedReviewers.length > 0) {
      setLoading(true)
      // if (selectedReviewers.length === reviewers.length) {
      //   const temp = {
      //     tripId: id,
      //     tripDetailsUid: selectedReviewers[0],
      //     nameofrequestor: "0",
      //   };
      //   post("reviews/processReviewProcessCashAdvance", temp)
      //     .then((res) => {
      //       if (res) {
      //         toast.success("Updated All Successfully", {
      //           position: "top-right",
      //           autoClose: 5000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //           draggable: true,
      //           progress: undefined,
      //           theme: "colored",
      //           transition: Bounce,
      //         });
      //       }
      //       setLoading(false)
      //     })
      //     .catch((err) => {
      //       setLoading(false)
      //       console.error(err);
      //       toast.error("Updated All Failed", {
      //         position: "top-right",
      //         autoClose: 5000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "colored",
      //         transition: Bounce,
      //       });
      //     });
      // }else{
        const responses = await Promise.all(
          selectedReviewers.map(async (d) => {
            const tempReviewer = editedData.filter(
              (r) => r.trip_details_uid == d
            );
            console.log(tempReviewer, 'tempReviewer', editedData)
            var tempObj = {
              tripId: id,
              tripDetailsUid: tempReviewer[0]?.trip_details_uid
            };
          
            const response = await post('reviews/processReviewProcessCashAdvance', tempObj);
            console.log(response, "resss");
            if (response) {
              return response;
            } else {
              return response;
            }
          })
        );
        if(responses.length > 0) {
          setLoading(false)
          toast.success("Updated The Items Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          // window.location.reload()
        }else{
          setLoading(false)
          toast.error("Update Items Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      // }
    }else{
      toast.error("Nothing to update", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  }

  const columns = [
    {
      field: "FName",
      headerName: "Reviewer",
      type: "",
      cellRenderer : (col, row) => {
        var name = '';

        if(row.FName){
          name = row.FName;
        }
        if(row.LName){
          name += " " + row.LName;
        }

        return <span>{name}</span>
      }
    },
    {
      field: "deltekvendorid",
      headerName: "DanyaID",
      type: "",
    },
    {
      field: "Name_of_Requestor",
      headerName: "Requestor",
      type: "text",
      edit: true,
    },
    {
      field: "Request_Date",
      headerName: "Date of Request",
      type: "date",
      edit: true,
    },
    {
      field: "Check_Needed",
      headerName: "Date of Check is Needed",
      type: "date",
      edit: true,
    },
    {
      field: "CashAdvanceSent",
      headerName: "C/A Required",
      type: "checkbox",
      edit: true,
    },
    {
      field: "advanceamt",
      headerName: "C/A Amount",
      amount:true
    },
    {
      field: "advance_processed",
      headerName: "Processed On",
      type: "date",
    },
  ];

  return (
    <div className="bg-white p-2">
      {loading && <Loader />}
      <div className=" text-justify font-bold text-xl">Process Review</div>
      <div className="flex flex-col justify-between py-2">
        <div className="px-4 py-2 flex flex-col gap-4">
          <div className="flex gap-1">
            <div>
              <MultiSelect
                label="Select Reviewers"
                options={reviewersOptions}
                selectedValues={selectedReviewers}
                onChange={setSelectedReviwers}
                placeholder={"Select Reviewers"}
              />
              {/* <Select
                label="Select Multiple Reviewers"
                options={[]}
              /> */}
            </div>
            {/* <div className="mt-auto">
                <CheckBox label={"Multiple Reviewer"}  />
              </div> */}
          </div>
          {/* <div className="flex gap-3">
              <div>
              <Select
                label="Select A Reviewer"
                options={[]}
              />
              </div>
              <div className="mt-auto">
                <CheckBox label={"All"}  />
              </div>
            </div> */}
          <div>
            <DynamicTable
              data={tableData}
              columns={columns}
              updateColumnData={updateColumnData}
            />
          </div>
          {/* {selectedReviewers && selectedReviewers.length > 0 ? (
            <div className="grid grid-cols-2 gap-3">
              {selectedReviewers.map((reviewer, i) => {
                return (
                  <div key={i} className=" shadow-md border border-gray-200 p-4 flex flex-col gap-3">
                    <div className="flex gap-6  text-left">
                      <div className="">
                        <TextWithLabel label="Danya ID" value="name 123" />
                      </div>
                      <Input label="Requestor" />
                    </div>
                    <div className="flex flex-row gap-8 text-left">
                      <InputDate label="Date of Request" />
                      <InputDate label="Date of Check is Needed" />
                      <InputToggle
                        label="C/A Required"
                        checked={cAToogleEnabled}
                        onClick={() => setCAToggleEnabled(!cAToogleEnabled)}
                      />
                      <TextWithLabel label="C/A Amount" value="n/a" />
                    </div>
                    <div className="flex flex-row gap-8 text-left">
                      <TextWithLabel label="Processed On" value="n/a" />
                      <TextWithLabel
                        label="Transferred to Deltek On"
                        value="12/28/2024 13:34:53 PM"
                      />
                      <TextWithBadge
                        label="Status"
                        value="Success"
                        bg="bg-green-200 text-green-600"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )} */}
        </div>

        <div className="flex flex-row gap-8 text-left pt-4">
          <PrimaryButton
            className={"py-3 px-10"}
            label={"Update"}
            onClick={() => handleUpdate()}
          />
          <TertiaryButton
            className={"py-3 px-10"}
            label={"Internal Team Summary"}
          />
          <TertiaryButton
            className={"py-3 px-10"}
            label={"Update Process Status"}
            onClick={() => handleUpdateProcess()}
          />
          <TertiaryButton className={"py-3 px-10"} label={"Email"} onClick={() => navigate(`/reviews/${id}/send-email`)}/>
        </div>
      </div>

      <PrimaryModal
        isOpened={isModalOpened}
        handleModal={(e) => setModalOpened(e)}
      >
        <div className="flex flex-col gap-8 w-full p-3">
          <h6 className="p-0 m-0 font-semibold text-[20px] text-center">
            Are you sure you want to update all?
          </h6>
          <p className="p-0 m-0 text-gray-600 text-[16px] text-left">
            These reviewers records have been edited and will be updated if you
            choose to proceed
          </p>
          <div className="text-left">
            <div className="p-0 m-0 font-semibold text-[17px]">
              Regina McCurty <span className="p-0 m-0 font-normal">REN007</span>
            </div>
            <div className="p-0 m-0 font-semibold text-[17px]">
              Regina McCurty <span className="p-0 m-0 font-normal">REN007</span>
            </div>
          </div>
          <div className="flex items-center gap-4 justify-center">
            <SecondaryButton
              onClick={() => setModalOpened(false)}
              label={"Cancel"}
              className={"w-[110px] h-[46px]"}
            />
            <PrimaryButton
              onClick={() => navigate(`/reviews/${id}/send-email`)}
              label={"Update All"}
              className={"w-[110px] h-[46px]"}
            />
          </div>
        </div>
      </PrimaryModal>
    </div>
  );
};

export default ProcessReviewEditTab;
