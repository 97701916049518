import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { currencyFormatter } from "../../utils/formatCurrency";
import InputDate from "../inputs/date";



const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    const group = currentItem[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(currentItem);
    return result;
  }, {});
};

export const DynamicTable = ({
  groupByField,
  columns,
  data,
  updateColumnData

}) => {
  const [tableData, setData] = useState(data);

  console.log(data, "datasdsdsd", tableData);

  useEffect(() => {
    setData(data);
  }, [data]);

  const groupedData = groupBy(tableData, groupByField);

  const finalTotal = columns.reduce((totals, col) => {
    if (col.aggregate === "sum") {
      totals[col.field] = tableData.reduce(
        (sum, item) => sum + (item[col.field] || 0),
        0
      );
    }
    return totals;
  }, {});

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    if (bodyRef.current) {
      setScrollbarWidth(
        bodyRef.current.offsetWidth - bodyRef.current.clientWidth
      );
    }
  }, []);

  const handleInputChange = (value, rowIndex, field) => {
    const updatedData = [...tableData];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [field]: value,
    };
    setData(updatedData);
  };

  const getInputElement = (col, value, onChangeHandler) => {
    switch (col.type) {
      case "date":
        return (
          <InputDate
            type="date"
            defaultValue={value ? dayjs(value).format("YYYY-MM-DD") : new Date()}
            onChange={(e) => onChangeHandler(e.target.value)}
            className="w-full border bg-white border-gray-300 p-1"
          />
        );
      case "checkbox":
        return (
          <input
            type="checkbox"
            checked={!!value}
            onChange={(e) => onChangeHandler(e.target.checked)}
            className="w-5 h-5"
          />
        );
      case "select":
        return (
          <select
            value={value || ""}
            onChange={(e) => onChangeHandler(e.target.value)}
            className="w-full border bg-white border-gray-300 p-1"
          >
            {(col.options || []).map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:

        return (
          <input
            type="text"
            value={value || ""}
            onChange={(e) => onChangeHandler(e.target.value)}
            className="w-full border bg-white border-gray-300 p-1"
          />
        );
    }
  };

  return (
    <div className="relative">

      <div
        className="overflow-hidden"
        style={{
          paddingRight: scrollbarWidth,
        }}
      >
        <table
          className="table-auto w-full border-collapse  bg-white
text-sm rounded-md"
          style={{ tableLayout: "fixed", paddingRight: scrollbarWidth, }}
        >
          <thead
            ref={headerRef}
            className="sticky top-0  text-white bg-indigo-600"
          >
            <tr>
              {columns.map((col) => (
                <th
                  key={col.field}
                  className="p-2  text-left"
                  style={{
                    width: col.width ? col.width : `${100 / columns.length}%`,
                  }}
                >
                  {col.headerName}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>

      {/* Body Table */}
      <div
        className="overflow-y-auto"
        ref={bodyRef}
        style={{
          maxHeight: "400px",
        }}
      >
        <table
          className="table-auto w-full border-collapse border
bg-white border-gray-300 text-sm"
          style={{ tableLayout: "fixed" }}
        >
          <tbody>
            {Object.keys(groupedData).map((groupKey) => {
              const items = groupedData[groupKey];
              const groupTotals = columns.reduce((acc, col) => {
                if (col.aggregate === "sum") {
                  acc[col.field] = items.reduce(
                    (sum, item) => sum + (item[col.field] || 0),
                    0
                  );
                }
                return acc;
              }, {});

              return (
                <React.Fragment key={groupKey}>
                  {items.map((item, index) => (
                    <tr
                      key={`${groupKey}-${index}`}
                      className=" bg-white border-gray-300"
                    >
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2 bg-white border
border-gray-300 text-left"
                          style={{
                            width: col.width ? col.width : `${100 /
columns.length}%`,
                          }}
                        >
                          {col.edit && col.field ? (
                            getInputElement(
                              col,
                              item[col.field],
                              (newValue) => {
                                handleInputChange(newValue, index, col.field);
                                updateColumnData&&
updateColumnData(newValue, index, col.field);
                              }
                            )
                          ) : index === 0 && col.field === groupByField ? (
                            col.amount
                              ? currencyFormatter(groupKey)
                              : groupKey
                          ) : col.type === "date" ? (
                            item[col.field] ? col.time ?dayjs(item[col.field]).format("MM/DD/YYYY hh:mm:ss A") : dayjs(item[col.field]).format("MM/DD/YYYY") : ''
                          ) : col.amount ? (
                            currencyFormatter(item[col.field])
                          ) : col.cellRenderer ? col.cellRenderer(col, item) : (
                            <span dangerouslySetInnerHTML={{__html :item[col.field]}}></span>
                          )}
                        </td>
                      ))}

                    </tr>
                  ))}
                  {items.length > 1 && groupByField && (
                    <tr className="font-bold  bg-white border-gray-300">
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="p-2  bg-white border border-gray-300
text-left text-indigo-600"
                          style={{
                            width: col.width
                              ? col.width
                              : `${100 / columns.length}%`,
                          }}
                        >
                          {col.aggregate === "sum"
                            ? col.amount ?
                            currencyFormatter(groupTotals[col.field]) : groupTotals[col.field]
                            : ""}
                        </td>
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
            {
              columns && columns.filter((d) => d.aggregate).length > 0
&& <tr className="font-bold ">
              {columns.map((col, indx) => (
                <td
                  style={{
                    width: col.width ? col.width : `${100 / columns.length}%`,
                  }}
                  key={col.field}
                  className="p-2 sticky bottom-0 border  bg-white
border-gray-300 text-left text-indigo-600"
                >
                  {col.aggregate === "sum"
                    ? col.amount ?
                    currencyFormatter(finalTotal[col.field]) : finalTotal[col.field]
                    : indx === 0
                      ? "Total"
                      : ""}
                </td>
              ))}
            </tr>
            }

          </tbody>
        </table>
      </div>
    </div>
  );
};
