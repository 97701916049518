import * as React from 'react'

import { reducer, intialState } from './reducers'

export const Store = React.createContext(null)


Store.displayName = "state"

export const StateProvider = ({children}) => {
    const [globalState, dispatch] = React.useReducer(reducer, intialState)

    return <Store.Provider value={[globalState, dispatch]}>{children}</Store.Provider>

}

export const useStore = () => {
    const [state, dispatch] = React.useContext(Store);

    const userID = state.ssoDetails && state.ssoDetails && state.ssoDetails.userId ? state.ssoDetails.userId : ''

    return {
        ...state,
        ssoUserID: userID,
        dispatch
    }
}