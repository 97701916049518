import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
// import DataTable from "react-data-table-component";
import PrimaryButton from "../../components/buttons/primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'
import { get, post } from "../../services";
import Table from "../../components/table/table";
// import CheckBox from "../../components/inputs/checkbox";

import { toast, Bounce } from "react-toastify";
import { useStore } from "../../store";
import Loader from "../../components/loader";

export default function ProcessReviewsSendEmail() {
  const gridRef = useRef()
  const { id } = useParams();
  const { ssoUserID } = useStore();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  // const handleChange = ({ selectedRows }) => {
  //   // You can set state or dispatch with something like Redux so we can use the retrieved data
  //   console.log("Selected Rows: ", selectedRows);
  // };

  useEffect(() => {
    get(`reviews/getReviewSendEmailReviewerList?trip_id=${id}`).then(res => {
      if(res && res.length >0){
        setData(res)
      }
    }).catch(err => {
      console.error(err)
    })
  }, [])
  

  const columns = [
    {
     headerCheckboxSelection:true,
     checkboxSelection:true,
     width:50
    },
    {
      field: "reviewer_name",
      headerName: "Reviewer"
    },
    {
      field: "email",
      headerName: "Email To",
    },
    {
      field: "",
      headerName: "Status",
      width:130
    },
    {
      field: "advanceamt",
      headerName: "CA Amount",
      amount:true
    },
    {
      field: "advance_processed",
      headerName: "CA Processed ON",
      type:"date",
      time:"off"
    },
    {
      field: "packets_mailed",
      headerName: "Packets Mailed ON",
      type:"date",
      time:"off"
    },
    {
      field: "CashAdvanceEmailSentOn",
      headerName: "Emailed ON",
      type:"date",
      //time:"off"
    }
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const sendEmails = async() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log(selectedRows, 'selected')

    if(selectedRows && selectedRows.length){
      setLoading(true)
      try {
        const responses = await Promise.all(
          selectedRows.map(async (d) => {
            
  
            var tempObj = {
              "trip_details_uid": d.trip_details_uid,
              "email": d.email,
              "userId": ssoUserID
          }
          
            const response = await post('pdfMaker/generateEmail', tempObj);
            console.log(response, "resss");
            if (response) {
              return response;
            } else {
              return response;
            }
          })
        );
        if(responses.length > 0) {
          setLoading(false)
          toast.success("Updated The Items Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          window.location.reload()
        }else{
          setLoading(false)
          toast.error("Update Items Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      } catch (error) {
        setLoading(false)
        console.error(error)
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
      
    } else{
      toast.error("Select some items", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } 
  }




  return (
    <div className="flex flex-col my-5 text-left">
      {
        loading && <Loader />
      }
      <PrimaryBreadCrumbs label="Review Search" path={`/reviews/details/${id}?tab=4`} />
      <div className="py-5">
      <TitlePrimary title={"Send Emails"}/>
      </div>
      <div className=" flex gap-3">
        <div className="w-[200px]">
          <TextWithLabel label="Review ID" value={id} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Review Date" value={data && data[0] && data[0].review_date } />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Grantee" value={data && data[0] && data[0].Name} />
        </div>
      </div>
      <div className="mt-5">
        <Table
          gridRef={gridRef}
          columns={columns}
          defaultColDef={defaultColDef}
          data={data}
          rowSelection="multiple"


          // pagination
          // selectableRows
          // onSelectedRowsChange={handleChange}

        />
      </div>
      <div>
        <PrimaryButton label="Send Email" className={"px-3 py-2 mt-5"} onClick={() =>sendEmails()}/>
      </div>
    </div>
  );
}
