import React, { useState, useEffect } from "react";
import Select from "../../components/inputs/select";
import TertiaryButton from "../../components/buttons/tertiary";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";

import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
// import RadioGroup from "../../components/inputs/radio-group";
import CashAdvanceStatus from "../../components/reviewer-status/cash-advance-status";
import ForDanyaUse from "../../components/reviewer-status/for-danya-use";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get, post } from "../../services";
import { useParams } from "react-router-dom";
// import Divider from "../../components/divider";
import PrimaryBox from "../../components/box/box-primary";
import { getSetValue } from "../../utils";

import { toast, Bounce } from "react-toastify";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { useStore } from "../../store";
import CheckBox from "../../components/inputs/checkbox";
import { useNavigate } from "react-router-dom";

const modifyDate = (dateRes) => {
  if (dateRes && dateRes !== "Invalid Date") {
    return dateRes;
  } else {
    return "";
  }
};

const changeTypes = [
  {
    label: "Select",
    value: null,
  },
  {
    label: "Change",
    value: 1,
  },
  {
    label: "Cancellation",
    value: 2,
  },
  {
    label: "Replacement",
    value: 3,
  },
  {
    label: "Add-On",
    value: 4,
  },
];

export default function ReviewersStatus() {
  const { ssoUserID } = useStore();
  const { id, reviewer_id } = useParams();
  const navigate = useNavigate()

  const [historyEnabled, setHistoryEnabled] = useState(false);
  console.log(reviewer_id, "reviewer_id");
  const [reviewersList, setReviewersList] = useState([]);
  const [data, setData] = useState({});
  const [selectedReviewer, setReviewerSelected] = useState("");
  const [reviewerHistory, setReviewerHistory] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [reviewer_details, setReviewerDetails] = useState({});
  // const [tis_details, setTISDetails] = useState([]);
  const [cashAdvance, setCashAdvance] = useState([]);
  const [ticketOwed, setTicketOwed] = useState(false);
  const [eticket, setEticket] = useState(false);
  const [confirmDetails, setConfirmDetails] = useState({});

  useEffect(() => {
    getReviewerHistory(id);
    get(`addon/getAddOnReviewerList?tripId=${reviewer_id}`)
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res);
          const reviewersTemp = res;
          setReviewersList(
            reviewersTemp.map((r) => {
              var temp = {};
              temp["label"] = r.rev_name;
              temp["value"] = r.trip_details_uid;
              return temp;
            })
          );
          if (id && id !== "all") {
            setReviewerSelected(id);
            getReviewerDetails(id);
            getReviewerHistory(id);
          } else if (reviewersTemp && reviewersTemp.length > 0) {
            setReviewerSelected(reviewersTemp[0].trip_details_uid);
            getReviewerDetails(reviewersTemp[0].trip_details_uid);
            getReviewerHistory(reviewersTemp[0].trip_details_uid);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getReviewerDetails = (rev_id) => {
    get(`addon/header_details?trip_detail_uid=${rev_id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setReviewerDetails(res[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // get(`reviews/tis_input_details?trip_details_uid=${rev_id}`)
    //   .then((res) => {
    //     if (res && res.length > 0) {
    //       setTISDetails(res);
          
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
      get(`addon/getAddOnTripDetails?tripDetailsUid=${rev_id}`)
      .then((res) => {
        if (res && res.length > 0) {
         
          setConfirmDetails(res[0])
        }
      })
      .catch((err) => {
        console.error(err);
      });
      get(`addon/cash_details?trip_detail_uid=${rev_id}`)
      .then((res) => {
        if (res && res.length > 0) {
         
          setCashAdvance(res)
          setTicketOwed(res[0].ticketowed)
          setEticket(res[0].e_tickets)
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getReviewerHistory = (rev_id) => {
    get(`addon/change_log?trip_detail_uid=${rev_id}`)
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res);
          setReviewerHistory(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setReviewerSelected(id);
  };

  const handleReviewerLookup = (e) => {
    if (e && e.target) {
      getReviewerDetails(e.target.value);
      getReviewerHistory(e.target.value);
    }
  };

  const handleChange = (e) => {
    console.log(e.target.type, e.target.value);
    if (e.target.name && e.target.value) {
      if (e.target.type === "checkbox") {
        if(e.target.name == 'ticketOwed'){
          setTicketOwed(e.target.checked)
        }else if(e.target.name == 'eTickets'){
          setEticket(e.target.checked)
        }
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.checked,
        }));
      } else if (e.target.name == "changeInStatus") {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
        if (e.target.value == 2) {
          //setSubTypes(subTypeCancellation)
          getSubTypes(2);
        } else if (e.target.value == 1) {
          //setSubTypes(subTypeChange)
          getSubTypes(1);
        }
      } else {
        setData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      }
    } else if (e.target.name) {
      setData((prev) => ({
        ...prev,
        [e.target.name]: "",
      }));
    }
  };

  const getSubTypes = (cID) => {
    get(`addon/getSubtypeList?change_in_status=${cID}`)
      .then((res) => {
        if (res && res.length > 0) {
          setSubTypes(() => {
            return res.map((d) => {
              return {
                label: d.SubType,
                value: d.SubTypeID,
              };
            });
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  }

  const doSubmit = () => {
    var temp = { ...data };
    temp["userId"] = ssoUserID;
    temp["tripDetailsUid"] = id;
    temp["eTickets"] = eticket ? 1 : 0
    temp["ticketOwed"] = ticketOwed ? 1 : 0
    temp["checkowed"] = 0

    post("addon/updateAddonStatus", temp)
      .then((res) => {
        console.log(res);
        if (res) {
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(granteeData)
  
    if(data && data.changeInStatus){
      if(data.subTypeID){
        if(data.subTypeID == "8"){
          if(data.otherChange){
            doSubmit()
          }else{
            showError('Others is mandatory')
          }
        }else if(data.subTypeID == "14"){
          if(data.otherCancel){
            doSubmit()
          }else{
            showError('Others is mandatory')
          }
        }else{
          doSubmit()
        }
      }else{
        showError('Sub  Type is mandatory')
      }
    }else{
      showError('Change Type is mandatory')
    }

  
  };
  console.log("selectedReviewer", selectedReviewer);

  var name = "";
  if (reviewer_details.Title) {
    name += reviewer_details.Title;
  }
  if (reviewer_details.FName) {
    name += " " + reviewer_details.FName;
  }
  if (reviewer_details.LName) {
    name += " " + reviewer_details.LName;
  }

  return (
    <div className="pb-10">
      <div>
        <PrimaryBreadCrumbs
          label="Reviewers"
          path={`/reviews/details/${reviewer_id}?tab=1`}
        />

        <div className="flex justify-between">
          <TitlePrimary title={"Change Reviewer Status"} />
        </div>
        <div className="mt-10">
          <div className="flex flex-col gap-6 text-left">
            <div className="flex flex-col gap-6 text-left">
              <PrimaryBox>
                <div className="flex gap-6">
                  <div className="w-[400px]">
                    <Select
                      label="Select Reviewer"
                      options={reviewersList}
                      onChange={handleReviewerLookup}
                      selected={selectedReviewer}
                    />
                  </div>
                  <div className="ml-auto mt-auto flex gap-3">
                  <TertiaryButton
                      label={"Change Logs"}
                      className={"w-[160px] py-2 text-center text-sm"}
                      onClick={() => navigate(`/reviews/reviewer-status/logs/${id}/${reviewer_id}`)}
                    />
                    <TertiaryButton
                      label={"View Change History"}
                      className={"w-[160px] py-2 text-center text-sm"}
                      onClick={() => setHistoryEnabled(true)}
                    />
                  </div>
                </div>
              </PrimaryBox>
              <PrimaryBox>
                <div className="flex flex-col gap-6">
                  <div className="flex gap-6">
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Traveler ID"
                        value={getSetValue(reviewer_details?.ssn)}
                      />
                    </div>
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Last Prism"
                        value={modifyDate(
                          dayjs(
                            getSetValue(reviewer_details?.last_trained)
                          ).format("MM/DD/YYYY")
                        )}
                      />
                    </div>
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Last EHS"
                        value={modifyDate(
                          dayjs(
                            getSetValue(reviewer_details?.last_trained_ehs)
                          ).format("MM/DD/YYYY")
                        )}
                      />
                    </div>
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Last Outcomes"
                        value={modifyDate(
                          dayjs(
                            getSetValue(reviewer_details?.last_trained_oa)
                          ).format("MM/DD/YYYY")
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-[200px]">
                      <TextWithLabel label="Name" value={getSetValue(name)} />
                    </div>
                    <div className="w-[400px]">
                      <TextWithLabel
                        label="Address"
                        value={getSetValue(reviewer_details.HomeAddress1)}
                      />
                      {reviewer_details.HomeAddress2 && (
                        <TextWithLabel
                          label=""
                          value={getSetValue(reviewer_details.HomeAddress2)}
                        />
                      )}

                      <div className="flex">
                        <TextWithLabel
                          label=""
                          value={getSetValue(reviewer_details.HomeCity)}
                        />
                        ,
                        <TextWithLabel
                          label=""
                          value={getSetValue(reviewer_details.HomeState)}
                        />
                        ,
                        <TextWithLabel
                          label=""
                          value={getSetValue(reviewer_details.HomeZip)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Phone"
                        value={getSetValue(
                          formatPhoneNumber(reviewer_details.HomePhone)
                        )}
                      />
                    </div>
                    <div className="w-[400px]">
                      <TextWithLabel
                        label="Email"
                        value={getSetValue(reviewer_details.email)}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-[400px]">
                      <TextWithLabel
                        label="Grantee"
                        value={`${getSetValue(reviewer_details.granteename)} / ${reviewer_details.trip_id}`}
                      />
                    </div>
                    <div className="w-[400px]">
                      <TextWithLabel
                        label="Review Dates"
                        //value={getSetValue()}
                      />
                      <div className="text-sm flex gap-2 font-semibold">
                        <div>
                          {modifyDate(
                            dayjs(reviewer_details.MeetingDate).format(
                              "MM/DD/YYYY"
                            )
                          )}
                        </div>
                        <div>-</div>
                        <div>
                          {modifyDate(
                            dayjs(reviewer_details.ExitDate).format(
                              "MM/DD/YYYY"
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-full">
                      <TextWithLabel
                        label="TER Notes"
                        value={getSetValue(reviewer_details.ter_notes)}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-full">
                      <TextWithLabel label="Email Notifications" value={""} />
                      <TextWithLabel
                        label=""
                        value={`Confirmation: ${getSetValue(confirmDetails.conf_email)} ${modifyDate(dayjs(getSetValue(confirmDetails?.conf_email_date)).format("MM/DD/YYYY hh:mm:ss A"))}`}
                      />
                      <TextWithLabel
                        label=""
                        value={`Cancellation: ${getSetValue(confirmDetails.can_email)} ${modifyDate(dayjs(getSetValue(confirmDetails?.can_email_date)).format("MM/DD/YYYY hh:mm:ss A"))}`}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Notified TDMG"
                        value={modifyDate(
                          dayjs(
                            getSetValue(reviewer_details?.last_trained_ehs)
                          ).format("MM/DD/YYYY")
                        )}
                      />
                    </div>
                    <div className="w-[200px]">
                      <TextWithLabel
                        label="Submitted"
                        value={modifyDate(
                          dayjs(
                            getSetValue(confirmDetails?.team_submitted_date)
                          ).format("MM/DD/YYYY hh:mm:ss A")
                        )}
                      />
                    </div>
                  </div>
                </div>
              </PrimaryBox>

              {/* <Divider /> */}
              <form
                onChange={handleChange}
                onSubmit={handleSubmit}
                className="flex flex-col gap-6"
              >
                <PrimaryBox>
                  <div className="flex flex-col gap-6">
                    <div className="flex gap-6">
                      <div className="w-[200px]">
                        <Select
                          label="Change Type"
                          name="changeInStatus"
                          id="changeInStatus"
                          options={changeTypes}
                          required={true}
                        />
                      </div>
                      {data &&
                        data.changeInStatus &&
                        (data.changeInStatus == 2 ||
                          data.changeInStatus == 1) && (
                          <div className="w-[400px]">
                            <Select
                              label="Sub Type"
                              name="subTypeID"
                              id="subTypeID"
                              options={subTypes}
                              required={true}
                            />
                          </div>
                        )}

                      {data &&
                        data.changeInStatus &&
                        data.changeInStatus == 1 &&
                        data.subTypeID == 8 && (
                          <div className="w-[400px]">
                            <Input
                              id="otherChange"
                              name="otherChange"
                              label="Others"
                              required={true}
                            />
                          </div>
                        )}
                      {data &&
                        data.changeInStatus &&
                        data.changeInStatus == 2 &&
                        data.subTypeID == 14 && (
                          <div className="w-[400px]">
                            <Input
                              id="otherCancel"
                              name="otherCancel"
                              label="Others"
                              required={true}
                            />
                          </div>
                        )}
                    </div>
                    <div>
                      <div className="w-full">
                        <Input
                          type="textArea"
                          name="reason"
                          id="reason"
                          label="Description"
                          required={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-6">
                        <SecondaryButton
                          className={"py-3 px-10"}
                          label={"Cancel"}
                          type="button"
                        />
                        <PrimaryButton
                          type="submit"
                          className={"py-3 px-10"}
                          label={"Update"}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </PrimaryBox>

                {/* <Divider /> */}
                <PrimaryBox>
                  <div className="flex flex-col gap-6 text-left">
                    <h6 className="text-[20px] font-[500]">
                      For Cancelled Reviews
                    </h6>
                    <div className="flex gap-6">
                      <div className="w-[200]">
                        <CheckBox
                          label="Ticket Owed to Danya"
                          name="ticketOwed"
                          id="ticketOwed"
                          checked={ticketOwed}
                        ></CheckBox>
                        {/* <RadioGroup
                          label="Ticket Owed to Danya"
                          name="ticket"
                          id="ticket"
                          groups={[
                            {
                              id: "ticketOwed",
                              label: "Yes",
                              name: "ticketOwed",
                              value: "Yes",
                            },
                            {
                              id: "ticketOwed",
                              label: "No",
                              name: "ticketOwed",
                              value: "No",
                            },
                          ]}
                        /> */}
                      </div>
                      <div className="w-[200]">
                      <CheckBox
                          label="E-Ticket"
                          name="eTickets"
                          id="eTickets"
                          checked={eticket}
                        ></CheckBox>

                        {/* <RadioGroup
                          label="E-Ticket"
                          name="ticket"
                          id="ticket"
                          groups={[
                            {
                              id: "e_tickets",
                              label: "Yes",
                              name: "e_tickets",
                              value: "Yes",
                            },
                            {
                              id: "e_tickets",
                              label: "No",
                              name: "e_tickets",
                              value: "No",
                            },
                          ]}
                        /> */}
                      </div>
                      <div className="flex gap-2">
                        <div className="text-text-secondary font-[400] text-[14px]">Check Owed to Danya</div>
                        <div className="font-semibold">{cashAdvance && cashAdvance.length >0 && cashAdvance[0].checkowed ? "Yes" : "No"}</div>
                        {/* <TextWithLabel label="Check Owed to Danya" value={tis_details.CheckOwed ? "Yes" : "No"} /> */}
                      </div>
                    </div>
                    {/* <div>
                      <TertiaryButton
                        label="Update"
                        className={"p-2 text-xs"}
                      />
                    </div> */}
                  </div>
                </PrimaryBox>
                <div className="flex flex-col gap-6 text-left">
                  {/* <Divider /> */}
                  <PrimaryBox>
                    <CashAdvanceStatus data={cashAdvance} />
                  </PrimaryBox>

                  {/* <Divider /> */}
                  <PrimaryBox>
                    <ForDanyaUse data={cashAdvance} />
                  </PrimaryBox>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {historyEnabled ? (
        <div className="fixed top-0 bottom-0 right-0">
          <div className="bg-white shadow-lg w-[496px] max-h-screen min-h-screen p-10 text-left overflow-auto">
            <div className="w-full flex">
              <div className="ml-auto">
                <Close
                  className="cursor-pointer"
                  onClick={() => setHistoryEnabled(false)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 py-10 ">
              <h6 className="text-[18px] font-[500] text-[#030712]">
                Change History
              </h6>
              {reviewerHistory && reviewerHistory.length > 0 ? (
                reviewerHistory.map((history, i) => {
                  return (
                    <div
                      key={i}
                      className="bg-[#F1F5F9] p-4 flex flex-col gap-3"
                    >
                      <div>
                        <div className="w-[196px]">
                          <TextWithLabel
                            label="Date"
                            value={dayjs(history.createts).format(
                              "MM/DD/YYYY hh:mm A"
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex gap-8">
                        <div className="w-[196px]">
                          <TextWithLabel label="Change Type" value={history.change_type} />
                        </div>
                        <div className="w-[196px]">
                          <TextWithLabel
                            label="Sub Type"
                            value={history.sub_reason}
                          />
                        </div>
                      </div>
                      <div className="flex gap-8">
                        <div className="w-[196px]">
                          <TextWithLabel
                            label="Description"
                            value={history.reason}
                          />
                        </div>
                        <div className="w-[196px]">
                          <TextWithLabel
                            label="Entered By"
                            value={(history.fname ? history.fname : '') + ' ' + (history.lname ? history.lname : '')}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div> No change history available</div>
              )}
            </div>
          </div>

          <div></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
