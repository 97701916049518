import React, { useState, useEffect } from "react";
import Input from "../../inputs/input";
import TertiaryButton from "../../buttons/tertiary";
// import InputDate from "../../inputs/date";
import Select from "../../inputs/select";
// import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../buttons/primary";
import SecondaryButton from "../../buttons/secondary";
import { get, post } from "../../../services";
import Loader from "../../loader";
import { useParams } from "react-router-dom";
import { DynamicTable } from "../../table/tableWithTotal";

import { toast, Bounce } from "react-toastify";

export default function UpdateAtCostLetterTab() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [fiscals, setFiscals] = useState([]);
  const [reviewOptions, setReviewOptions] = useState([]);
  const [reviewID, setReviewID] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFiscal, setSelectFiscal] = useState(null);
  const [selectedRegion, setSelectRegion] = useState(null);
  const [data, setData] = useState([]);
  const [selectedReviewerOption, setSelctedReviewerOption] = useState(null);
  const [editedData, setEditedData] = useState([])

  useEffect(() => {
    get(`atCostLetter/atCostLetterFiscalYear`)
      .then((res) => {
        if (res && res.length > 0) {
          setFiscals([
            { label: "Select one", value: "null" },
            ...res.map((d) => {
              d["label"] = d.display_year;
              d["value"] = d.fiscal_year;
              return d;
            }),
          ]);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    get(`atCostLetter/atCostLetterRegionList`)
      .then((res) => {
        if (res && res.length > 0) {
          setRegions(
            res.map((d) => {
              d["label"] = d.RegionDisplay;
              d["value"] = d.RegionValue;
              return d;
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });

    setLoading(true);
    get(`atCostLetter/atCostLetterHotelDetails?tripId=${id}&option=TripId`)
      .then((res) => {
        if (res && res.length > 0) {
          setData(res);
        }else{
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const handleReviewID = (val) => {
    navigate("/reviews/details/" + val);
  };

  const columns = [
    {
      field: "trip_id",
      headerName: "Review ID",
      cellRenderer: (col, data) => (
        <div
          className=" text-indigo-600 text-md"
          onClick={() => handleReviewID(data.trip_id)}
        >
          {data.trip_id}
        </div>
      ),
    },
    {
      field: "hotel_nights",
      headerName: "Hotel Nights",
    },
    {
      field: "ftl_name",
      headerName: "FTL",
    },
    {
      field: "hotel_name",
      headerName: "Hotel Name",
    },
    {
      field: "county_name",
      headerName: "County/City",
    },
    {
      field: "Lodging",
      headerName: "Available Lodging",
      amount:true
    },
    {
      field: "bookedrate",
      headerName: "Booked Rate",
      amount:true
    },
    {
      field: "letter_rcvd",
      headerName: "Recieved On",
      type:'date',
      edit:true
    },
  ];

  // const columns = [
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Review ID</div>,
  //     selector: (row) => row.trip_id,
  //     sortable: true,
  //     cell: (row) => (
  //       <div
  //         className=" text-indigo-600 text-md"
  //         onClick={() => handleReviewID(row.trip_id)}
  //       >
  //         {row.trip_id}
  //       </div>
  //     ),
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Hotel Nights</div>,
  //     selector: (row) => row.hotel_nights,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">FTL</div>,
  //     selector: (row) => row.ftl_name,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Hotel Name</div>,
  //     selector: (row) => row.hotel_name,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Country/City</div>,
  //     selector: (row) => row.county_name,
  //     sortable: true,
  //   },
  //   {
  //     name: (
  //       <div className="uppercase text-md text-gray-600">Available Lodging</div>
  //     ),
  //     selector: (row) => row.Lodging,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Booked Rate</div>,
  //     selector: (row) => row.bookedrate,
  //     sortable: true,
  //   },
  //   {
  //     name: <div className="uppercase text-md text-gray-600">Recieved On</div>,
  //     selector: (row) => row.letter_rcvd,
  //     sortable: true,
  //   },
  // ];
  // const data = [
  //   {
  //     review_id: "01234F2C",
  //     hotelNights: "20",
  //     ftl: "Merlin Monro",
  //     hotelName: "The Line Hotel (Wilshire Plaza)",
  //     country: "Los Angeles",
  //     availableLoding: "$120",
  //     bookedRate: "$150",
  //     recievedOn: "2/04/2024",
  //   },
  //   {
  //     review_id: "01234FGD",
  //     hotelNights: "12",
  //     ftl: "Joe Lee",
  //     hotelName: "The Line Hotel (Wilshire Plaza) Test",
  //     country: "Los Angeles",
  //     availableLoding: "$250",
  //     bookedRate: "350",
  //     recievedOn: "2/04/2024",
  //   },
  // ];

  const validateNow = () => {
    setLoading(true);
    get(
      `atCostLetter/atCostLetterHotelDetails?tripId=${reviewID}&option=TripId`
    )
      .then((res) => {
        if (res && res.length > 0) {
          setData(res);
        }else{
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const validateYears = () => {
    if (!selectedFiscal || !selectedRegion) {
      if (!selectedFiscal) {
        toast.error("Select Fiscal", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      } else if (!selectedRegion) {
        toast.error("Select Region", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    } else {
      setLoading(true);
      get(
        `atCostLetter/atCostLetterReviewList?region=${selectedRegion}&fiscalYear=${selectedFiscal}`
      )
        .then((res) => {
          if (res && res.length > 0) {
            const tempd = res.map((d) => {
              d["label"] =
                d.trip_id && d.trip_id.length > 0 ? d.trip_id[0] : "";
              d["value"] =
                d.trip_id && d.trip_id.length > 0 ? d.trip_id[1] : "";
              return d;
            })
            setReviewOptions(tempd);
            if(tempd && tempd.length >0){
              getData(tempd[0].value)
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const handleReviewOptions = (e) => {
    e.preventDefault();

    if (e.target && e.target.value) {
      getData(e.target.value)
    }
  };
  const getData = (rID) => {
    setLoading(true);
    setSelctedReviewerOption(rID)
    get(
      `atCostLetter/atCostLetterHotelDetails?tripId=${rID}&option=TripId`
    )
      .then((res) => {
        if (res && res.length > 0) {
          setData(res);
        }else{
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }
  const updateColumnData = (value, rowIndex, field) => {
    console.log(value, rowIndex, field);
    const updatedData = [...data].filter((d, i) => i == rowIndex);
    const temp = {
      ...updatedData[0],
      [field]: value,
    };
    console.log(temp, 'updatedData')
    setEditedData([...editedData, temp]);
  };

  const submit=() => {
    if(editedData && editedData.length >0){
      setEditedData([])
      editedData.forEach((e, i) => {
        post(`atCostLetter/atCostLetterUpdateDate`,{
          "tripHotelUid":e.trip_hotel_uid,
          "letterRcvdDate":e.letter_rcvd
      }
       ).then(res => {
          if(res){
            if(i == editedData.length -1){
              toast.success("Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
            }
          }
        }).catch(err => {
          console.error(err)
        })
      })
    }
  }

  return (
    <div className=" flex flex-col gap-3 text-left p-2 bg-white rounded-md">
      <h5 className="font-semibold text-[20px]">Update At Cost Letter</h5>
      {loading && <Loader />}
      <div className="flex gap-3">
        <div className="w-[192px]">
          <Input
            label="Review ID"
            value={reviewID}
            onChange={(e) => setReviewID(e.target.value)}
          />
        </div>
        <div className="mt-auto">
          <TertiaryButton
            disabled={!reviewID}
            onClick={validateNow}
            className={"px-4 py-3"}
            label={"Validate Now"}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-[192px]">
          <Select
            selected={selectedFiscal}
            options={fiscals}
            label="Fiscal Year"
            onChange={(e) => setSelectFiscal(e.target.value)}
          />
          {/* <InputDate label="Fiscal Year" /> */}
        </div>
        <div className="w-[192px]">
          <Select
            selected={selectedRegion}
            onChange={(e) => setSelectRegion(e.target.value)}
            options={regions}
            label="Region"
          />
        </div>
        <div className="mt-auto">
          <TertiaryButton
            type="button"
            onClick={validateYears}
            className={"px-4 py-3"}
            label={"Validate Now"}
          />
        </div>
        <div className="w-[315px]">
          <Select
            selected={selectedReviewerOption}
            options={reviewOptions}
            onChange={handleReviewOptions}
            label="Select A Review"
          />
        </div>
      </div>
      <div>
    
      </div>
      <div>
        <DynamicTable columns={columns} data={data} updateColumnData={updateColumnData}/>
      </div>
      <div className="flex gap-2 mt-6">
        <SecondaryButton className={"py-3 px-10"} label={"Cancel"} />
        <PrimaryButton
          type="submit"
          className={"py-3 px-10"}
          label={"Update"}
          onClick={submit}
        />{" "}
      </div>
    </div>
  );
}
