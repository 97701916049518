import React, { useState } from "react";
import PrimaryButton from "../../../buttons/primary";
import View from "./view";
import Edit from "./edit";

export default function ReviewerViewEdit({reviewer}) {
  const [isEditEnabled, setEdit] = useState(false);


  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center">
        <h6 className="font-[500] text-[18px]">{isEditEnabled? "Edit Details" : " View Details"}</h6>
        <div className="ml-auto">
          <PrimaryButton
            onClick={() => setEdit(!isEditEnabled)}
            label={isEditEnabled ? "Cancel" : "Edit"}
            className={"p-2"}
          />
        </div>
      </div>
      {isEditEnabled ? <Edit setEdit={setEdit} reviewer={reviewer}/> : <View reviewer={reviewer}/>}
    </div>
  );
}
