import React from "react";
import Table from "../table/table";

const ReviewsDataTableIncomplete = ({ reviewsData }) => {
  const columns = [
    {
      headerName: "Review ID",
      field: "trip_id",
      url: "/reviews/details",
      urlParams: ["trip_id"],
    },
    {
      headerName: "RP",
      field: "RP",
    },
    {
      headerName: "Name",
      field: "Name",
    },
    {
      headerName: "Review Type",
      field: "review_type",
    },
    {
      headerName: "Review Date",
      field: "review_date",
      // dateFilter: true,
      // type: "date",
    },
    {
      headerName: "Sub Type",
      field: "sub_type",
    },
  ];

  return <Table columns={columns} data={reviewsData} pagination />;
};

export default ReviewsDataTableIncomplete;
