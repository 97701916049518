import React, { useState } from "react";
// import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/hotel-reconciliation/rental-car";
import InputDate from "../../components/inputs/date";
import Select from "../../components/inputs/select";

import { toast, Bounce } from "react-toastify";
import Input from "../../components/inputs/input";
import Loader from "../../components/loader";

export default function HotelReconciliationRentalCar() {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [displayBy, setDisplayBy] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData && formData.display_by) {
      setLoading(true)
      setDisplayBy(formData?.display_by);
      console.log(formData);
      setData([]);
      var url = "hotelReconsile/hotelReconsileOutstandingReview";
      if (
        formData &&
        formData.display_by &&
        formData.display_by === "reviewer"
      ) {
        url = "hotelReconsile/hotelReconsileOutstandingReviewer";
      }
      if (!formData["region"]) {
        formData["region"] = 0;
      }
      Object.keys(formData).forEach((key, i) => {
        // if(key !== 'display_by'){

        if (i === 0) {
          url += `?${key}=${formData[key]}`;
        } else {
          url += `&${key}=${formData[key]}`;
        }
        //}
      });
      get(url)
        .then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            // setFormData({})
            setData(res);
          }
          setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
        });
    } else {
      toast.error("Select any Display By", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      setLoading(false)
    }
  };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  return (
    <div className="py-10 text-left">
      <TitlePrimary title={`Rental Car Reconciliation`} />
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="flex flex-col gap-3 mt-5"
      >
        <div className="flex gap-3">
          <div className="flex flex-col gap-3">
            <div>
              <Select label="Rental Agency" otions={[]} />
            </div>
            <div className="w-[200px]">
              <Input label="Trip ID" />
            </div>
            <div className="flex gap-1">
              <Input  className="w-[200px]" label="Reviewer Name" />
              <Input  className="w-[200px] mt-auto" label="" />
              <Select  className="w-[200px] mt-auto" label="" otions={[]} />
            </div>
            <div>
              <Input  className="w-[200px]" label="Grantee City" />
            </div>
            <div className="flex gap-3">
            <InputDate
              label="Reviewed Between"
              name="fromDate"
              id="fromDate"
            />
            <InputDate
              label="and"
              name="toDate"
              id="toDate"
            />
            </div>
            
          
          </div>

      
        </div>
        <div className="flex gap-3">
            <div className="w[200px] mt-auto">
              <PrimaryButton
                type="submit"
                label="Search Now"
                className={"px-3 py-2 "}
              />
            </div>
          </div>
      </form>
      <div className="flex flex-col gap-3 text-left mt-5">
        {displayBy && (
          <h6 className="font-semibold text-[18px] m-0 p-0">
            Search Results ( {data.length ? data.length : 0} )
          </h6>
        )}

{
          loading ? <Loader /> :<SearchResults data={data} display={displayBy}/>
        }
      </div>
    </div>
  );
}
