import React from "react";
import Table from "../table/table.jsx";

const SearchResults = ({ data }) => {
  const columns = [
    {
      field: "fname",
      headerName: "RTL Name",
      url: "/rtl/details",
      urlParams: ["ftl_uid"],
      cellRenderer : ({data}) => {
        var name = '';

        if(data.fname){
          name = data.fname;
        }
        if(data.lname){
          name += " " + data.lname;
        }

        return <span>{name}</span>
      }
      
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "phone",
    },
    {
      field: "ftl_status",
      headerName: "Status",
    },
    {
      field: "ftl_uid",
      headerName: "RTL ID",
    },
  ];

  return <Table columns={columns} data={data} />;
};

export default SearchResults;
